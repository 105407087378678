.business { color:#000;
    .page_title-section .section-inner {
        background-image:url('../../images/visual/vs_business.jpg'); background-repeat: no-repeat; background-position: center; 
    }

    .tit {font-size:50px; font-weight: 900; word-break: keep-all;}
    .txt {font-size:18px; font-weight: 400; line-height: 1.5; word-break: keep-all;}

    .business_type1 { margin-top:150px; margin-bottom:250px;
        .content-panel { display:flex; flex-wrap: wrap; align-items: flex-end; position: relative;
            .panel-header {
                width:56%;
                .txt {margin-top:90px;}
            }
            .panel-body {
                width:40%;// margin-left:4%;
            }
        }
        .lottiePlayer{position: absolute; right:0; width:50%; bottom:0; }
    }
    .business_type2 { margin-bottom: 150px;
        .panel-header {margin-bottom:60px;}
        .panel-body { display:flex; flex-wrap: wrap;
            .img_wrap {width:48%; align-items: center; display:flex;
                img {width: 100%;}
            }
            .txt_wrap {width:48%; margin-left:4%; align-items: center; display:flex;
                .txt {margin-bottom:30px;}
            }
        }
    }
    .business_type3 {margin-bottom: 150px;
        .panel-header {margin-bottom:90px;}
        .process_list {
            margin-bottom:80px; display: flex; flex-wrap: wrap;
            .pl_item { width:20%; text-align: center; position:relative; padding-bottom:46px;
                span {font-weight: 900; font-size:24px; color:#004098;}
                i {width:28px; height:28px; border-radius:50%; display:block; position:absolute; bottom:-14px; left:50%; margin-left:-14px;  z-index: 1;}
                .ic_dot0 {background:#004098;}
                .ic_dot1 {background:linear-gradient(90deg, #004098 0%, #558BCB 100%);}
                .ic_dot2 {background:#558BCB;}
                .ic_dot3 {background:linear-gradient(90deg, #71A7CC 0%, #7580BD 100%);}
                .ic_dot4 {background:#7580BD;}
                &:after {content:''; position:absolute; width:100%; height:1px; background:#c3cbcd; bottom:0; left:-50%;}
                &:first-child:after {display:none;}
            }
        }
        .txt {
            text-align: center;
        }
        .btn_wrap2 {
            text-align: center; margin-top:40px;
            a + a {margin-left:60px;}
        }
    }
    .business_type4 { margin-top:180px;
        .pr_desc { margin-top:40px; text-align: right; font-size:16px; font-weight: 400;}
        .pricing_cols { display:flex; flex-wrap: wrap;
            .pr_col { width:25%; padding:15px; 
                .pr_box { border:1px solid var(--col_color); border-radius: 30px; padding:20px; height:100%; padding-bottom:100px;
                    position:relative;
                    &.type0 {--col_color:#004098;
                        .pr_box_tit {padding-top:14px;}
                    }
                    &.type1 {--col_color:#558BCB;
                        .pr_box_tit {padding-top:14px;}
                    }
                    &.type2 {--col_color:#7580BD;}
                    .pr_box_tit{ font-size:24px; text-align: center; color:var(--col_color); font-weight: 900; letter-spacing: -2px; height:140px; word-break: keep-all; line-height: 1.3;}
                    .pr_box_sub_tit {font-size:18px; font-weight: 700; text-align: center; word-break: keep-all;line-height: 1.5;}
                    .pr_box_txt {font-size:18px; font-weight: 400; text-align: center; margin:30px 0 0;  word-break: keep-all;line-height: 1.5;}
                    .btn_wrap{position:absolute; bottom:0;left:0; padding:20px; width:100%;}
                    .btn {background:var(--col_color); color:#fff; font-size:18px; font-weight: 700; padding:10px; width:100%; border-radius: 24px; height:48px; line-height: 24px; text-align: center;

                    }
                }
            }
            &.pricing_info {  border-bottom:1px solid #F0F2F5;
                &:first-child{margin-top:30px;}
                align-items: center; padding:0 30px;
                .pr_col {
                    padding:22px 15px; 
                }
                .list_tit { font-size:24px; font-weight: 900;}
                .list_check { padding-top:45px; position:relative; text-align: center; position:relative;
                    --col_color:rgba(195, 203, 205, 0.9);
                    &.type0 {--col_color:#004098;
                        .pr_box_tit {padding-top:14px;}
                    }
                    &.type1 {--col_color:#558BCB;
                        .pr_box_tit {padding-top:14px;}
                    }
                    &.type2 {--col_color:#7580BD;}
                    i {display:block; position:absolute; top:0; left:50%; margin-left:-18px; width:36px; height:36px; border-radius: 18px; overflow: hidden;
                        background:var(--col_color);
                    }
                    &.checked i:before { content:''; width:20px; height:15px; display:block; position:absolute; left:50%; top:50%; transform: translate(-50%, -50%);
                        background:url('../../images/common/ic_check.svg'); background-size:100%; background-repeat: no-repeat;
                    }
                }
            }
        }
    }
    .business_banner { color:#fff;
        background:url('../../images/sub/bs_banner_bg.jpg'); background-size:cover; background-position: center center;
        .cont_box {padding: 80px; text-align: center;
            .tit {margin-bottom:35px;}
            .txt {margin-bottom:45px;}
        }
    }
}




@media all and (max-width:$G-wMobile)  {

    .business { 
    
        .tit {font-size:24px; font-weight: 900; word-break: keep-all;}
        .txt {font-size:14px; font-weight: 400; line-height: 1.5; word-break: keep-all;}
    
        .business_type1 { margin-top:100px; margin-bottom:150px;
            .content-panel { display:block;
                .panel-header {
                    width:100%;
                    .tit{ position: relative;
                        span {position: relative;}
                        &:before {
                            content: ""; z-index: -1; position: absolute; width: 104px; height: 104px; border-radius: 50%; background: #f0f2f5; overflow: hidden; left: -16px; top: -18px;
                        }
                    }
                    .txt {margin-top:0px;}
                }
                .panel-body {
                    //display:none;
                    
                }
            }
            .lottiePlayer{position: relative; max-width: 400px; margin:0 auto; width:auto; bottom:auto; right:auto;}
        }
        .business_type2 { margin-bottom: 150px;
            .panel-header {margin-bottom:35px;}
            .panel-body { display:block; 
                .img_wrap {width:auto;  margin:0 -16px;
                    img {width: 100%; max-width: 500px;}
                }
                .txt_wrap {width:100%; margin-left:0; margin-top:40px;
                    .txt {margin-bottom:30px;}
                }
            }
        }
        .business_type3 {margin-bottom: 150px;
            .panel-header {margin-bottom:40px;}
            .panel-body { text-align: center;}
            .process_list {
                margin-bottom:80px; display: inline-block;
                .pl_item { width:auto; text-align: left; position:relative; padding-bottom:0; padding-left:50px; margin-top:25px; 
                    &:first-child {margin-top:0;}
                    span {font-weight: 700; font-size:14px; color:#004098;}
                    i {width:28px; height:28px; border-radius:50%; display:block; position:absolute; bottom:50%; margin-bottom:-14px; left:0px; margin-left:0px;  z-index: 1;}
                    .ic_dot0 {background:#004098;}
                    .ic_dot1 {background:linear-gradient(180deg, #004098 0%, #558BCB 100%);}
                    .ic_dot2 {background:#558BCB;}
                    .ic_dot3 {background:linear-gradient(180deg, #71A7CC 0%, #7580BD 100%);}
                    .ic_dot4 {background:#7580BD;}
                    &:after {content:''; position:absolute; width:1px; height:40px; background:#c3cbcd; top:-100%; bottom:auto; left:14px;}
                    &:first-child:after {display:none;}
                }
            }
            .txt {
                text-align: left;
            }
            .btn_wrap2 {
                text-align: left; margin-top:40px;
                span {display:block;}
                a + a {margin-left:0;}
            }
        }
        .business_type4 { margin-top:150px; margin-bottom:90px;
            .pr_desc { margin-top:40px; text-align: left; font-size:16px; font-weight: 400;}
            .pricing_cols { display:block; flex-wrap: wrap;
                .pr_col { width:auto; padding:0px; 
                    .tit {margin-bottom:40px;}
                    .pr_box { border:1px solid var(--col_color); border-radius: 30px; padding:20px; height:100%; padding-bottom:0;
                        position:relative;
                        &.type0 {--col_color:#004098;
                            .pr_box_tit {padding-top:14px;}
                        }
                        &.type1 {--col_color:#558BCB;
                            .pr_box_tit {padding-top:14px;}
                        }
                        &.type2 {--col_color:#7580BD;}
                        .pr_box_tit{  font-size:24px; text-align: center; color:var(--col_color); font-weight: 900; letter-spacing: -2px; height:auto; word-break: keep-all; line-height: 1.3;}
                        .pr_box_sub_tit {margin-top:70px;font-size:14px; font-weight: 700; text-align: center; word-break: keep-all;line-height: 1.5;}
                        .pr_box_txt {font-size:14px; font-weight: 400; text-align: center; margin:30px 0 0;  word-break: keep-all;line-height: 1.5;}
                        .btn_wrap{position:relative; bottom:0;left:0; padding:20px; width:100%;margin-top:40px;}
                        .btn {background:var(--col_color); color:#fff; font-size:14px; font-weight: 700; padding:10px; width:100%; border-radius: 24px; height:48px; line-height: 24px; text-align: center;
    
                        }
                    }
                }
                &.pricing_info {  display:none;
                }

                .grade_options { margin-bottom:40px;
                    
                    &.type0 {--col_color:#004098;}
                    &.type1 {--col_color:#558BCB;}
                    &.type2 {--col_color:#7580BD;}

                    li {
                        position: relative; padding:33px 0 33px 94px; border-bottom:1px solid #F0F2F5;
                        i:after {content:''; width: 20px;height: 15px;display: block;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);background: url('../../images/common/ic_check.svg');background-size: 100%;background-repeat: no-repeat;}
                        i { position: absolute;
                            display: block;top: 50%;left: 40px;margin-top: -18px;width: 36px;height: 36px;border-radius: 18px;overflow: hidden;background: var(--col_color);
                        }
                        .option_tit {font-weight: 700; font-size:14px;}
                        .option_txt {font-weight: 400; font-size:14px; margin-top:5px;}
                    }
                }
            }
        }
        .business_banner { color:#fff;
            background:url('../../images/sub/bs_banner_bg.jpg'); background-size:cover; background-position: center center;
            .cont_box {padding: 80px 0px; text-align: center;
                .tit {margin-bottom:35px;}
                .txt {margin-bottom:45px;}
            }
        }
    }

}