.company { color:#000;
    .page_title-section .section-inner {
        background-image:url('../../images/visual/vs_company.jpg'); background-repeat: no-repeat; background-position: center; 
    }
    
    .tit {font-size:50px; font-weight: 900; word-break: keep-all;}
    .txt {font-size:18px; font-weight: 400; line-height: 1.5; word-break: keep-all;}

    .company_intro {
        padding-top:170px; padding-bottom:170px; background:#f0f2f5;
        text-align: center;
        .panel-body {margin-top:0px}
        .point_circle:before {
            content: "";
            z-index: 0;
            position: absolute;
            width: 312px;
            height: 312px;
            border-radius: 50%;
            background: #fff;
            overflow: hidden;
            left: 50%;
            top: 50%;
            margin-top:40px;
            transform: translate(-50%, -50%);
        }
        .tit span {position:relative;}
        .txt { position: relative;}
    }
    .company_info {
        margin-top:170px;
        .panel-body {margin-top:60px}
    }
    .company_history {
        margin-top:180px;
        .panel-body {margin-top:65px;
            display:flex; flex-wrap: wrap;
        }
        .history_wrap {  width:69%; padding-right:30px;
            dl { position:relative; padding-left:220px;
                &+dl {margin-top:50px;}
                dt {position:absolute; left:30px; top:0; 
                    font-size:24px; font-weight: 900; color:#004098;
                }
                dd { margin-top:12px;
                    &:first-child {margin-top:0;}
                    position:relative;

                    .month {position:absolute; left:-50px; top:0; color:#004098; font-size:18px; font-weight: 700; line-height: 1.5;}
                    &.hasImage {
                        .txt {position:relative; cursor: pointer;
                            &:before {content:''; width:6px; height:6px; border-radius: 3px; background:#004098; overflow: hidden; position:absolute; left:-10px; top:3px;}
                        }
                    }
                    .img_wrap {display:none;}
                }
            }
        }
        .history_image{width:31%; position: relative;
            .img_wrap {width:100%; border-radius: 30px; overflow: hidden;  position: absolute;
                box-shadow: 2px 4px 16px rgba(195, 203, 205, 0.6);
                img{width:100%;}
            }
        }
        .btn_wrap {display:none;}
    }
    .company_culture {
        margin-top:220px;
        .panel-body {
            margin-top:18px;
        }
        .cultrue_wrap {margin-top:70px;
            .culture_list {display:flex; flex-wrap: wrap; justify-content: space-between; position:relative;
                
                li { width:200px; position:relative;
                    &:first-child .c_tit_wrap:before {display:none;}
                    .c_tit_wrap {
                        position:relative;
                        
                        &:before {content:''; position:absolute; width:100%; height:1px; background:#3c64b1; top:50%; left:-100%;}
                    }
                    .c_tit{ position:relative; z-index: 1; display:flex; align-items: center; justify-content: center;
                        width:100%; padding-bottom:100%; border-radius: 50%; overflow: hidden; border:1px solid #3c64b1; background:#f0f2f5; text-align: center;
                        span {font-size:24px; font-weight: 900; color:#004098;position:absolute; left:50%; top:50%; transform: translate(-50%, -50%); width:100%; display:inline-block; padding: 15px; box-sizing: border-box;}
                       
                    }
                    .c_cont {margin-top:45px; text-align: center;}
                }
                @media all and (max-width:1200px)  {
                    li { width:150px; }
                }
            }
        }
    }
    .company_team {
        margin-top:240px;
        .team_intro {
            .tit {margin-bottom:48px;}
        }
        .team_member { margin-top:130px; padding-bottom: 140px;
            background:#f0f2f5; padding-top:30px;
            .team_list { display:flex; flex-wrap: wrap; justify-content: center; margin-left:-4%;
                li {padding:20px; margin-bottom:140px; min-height: 314px; margin-left:3%;
                    width:22%; padding-top:10%; position: relative; border-radius: 30px; background:#fff; padding-bottom:35px;
                    .img_wrap {width:60%; border-radius: 87px; overflow: hidden; position:absolute; top:-76px; left:50%; transform: translateX(-50%);
                        filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));
                        img {width:100%;}
                    }
                    .mem_info { text-align: center;
                        .name {font-weight: 700;}
                        .desc {margin-top:8px;}
                        .txt + .txt {margin-top:18px;}
                    }
                    .btn_wrap {text-align: center; position:absolute; left:50%; bottom:20px; margin-left:-12px;
                        button {width:24px; height:24px; display:inline-block; position:relative;
                            &:after {content:''; position:absolute; top:50%; left:50%; width:18px; height:18px; background:url('../../images/common/icon_linkedin_sm.png'); background-repeat: no-repeat; transform: translate(-50%, -50%);}
                            &:disabled {opacity: 0.4; cursor:default;}
                        }
                    }
                }
            }
            
        }
        .btn_box { text-align: center;}
        .career_btn { padding: 100px 0 200px; }
    }

    
    .simplebar-track {
        right:auto; left:0;
    }

    .simplebar-scrollbar:before {
        background-color: #004098; border-radius: 0;
    }
    .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
    }
    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
        top:0; bottom:0; left:0px; right:0;    border-radius: 3px;
    }
    .simplebar-track.simplebar-vertical {
        &:before {    border-radius: 3px;
            content:''; width:6px; height:100%; position:absolute; top:0; left:0px; background-color: #d9d9d9;
        }
    }
    .simplebar-track.simplebar-vertical {width:6px;}

}

.en {
    .company { 
        .company_culture {
            .cultrue_wrap {
                .culture_list {
                    li { width:200px; 
                        .c_tit{ 
                            span {font-size:18px; }
                        }
                    }
                    @media all and (max-width:1200px)  {
                        li { width:18%; 
                            .c_tit{ 
                                span {font-size:15px; }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media all and (max-width:$G-wMobile)  {


    .company { 
        
        .tit {font-size:24px;}
        .txt {font-size:14px;}
    
        .company_intro {
            padding-top:70px; padding-bottom:70px; 
            .panel-body {margin-top:0px}
            .point_circle:before {
                width: 170px;
                height: 170px;
            }
        }
        .company_info {
            margin-top:70px;
            .panel-body {margin-top:30px}
        }
        .company_history {
            margin-top:70px;
            .panel-header {display:none;}
            .panel-body {margin-top:0;
                display:flex; flex-wrap: wrap;
            }
            .history_wrap {  width:100%; padding-right:0;
                &.view {
                    >div {max-height: none !important;}
                }

                dl {  padding-left:0;
                    dt {position:relative; left:auto; top:auto; 
                        margin-bottom:13px;
                    }
                    dd { margin-top:0; padding: 6px 0; padding-left:48px; border-bottom:1px solid #F0F2F5;
                        .month { top:6px; left:0; font-size:14px; }
                        .txt {display:block;}
                        .img_wrap {display:inline-block; overflow: hidden; border-radius: 20px; margin-top:12px;
                            img{ width:100%; max-width: 420px;}
                        }
                    }
                }
            }
            .history_image{width:31%; display:none !important;
                .img_wrap {width:100%; border-radius: 30px; overflow: hidden; 
                    box-shadow: 2px 4px 16px rgba(195, 203, 205, 0.6);
                    img{width:100%;}
                }
            }
            .btn_wrap {display:block;margin-top:30px;
                &.hide {display:none;}
            }
        }
        .company_culture {
            margin-top:160px;
            .panel-body {
                margin-top:18px;
            }
            .cultrue_wrap {margin-top:70px;
                
                .culture_list {display:flex; flex-wrap: wrap; justify-content: space-between; position:relative;
                    
                    li { width:auto; position: relative; padding-left:155px; min-height: 124px; margin-bottom:78px;
                        .c_tit_wrap { width: auto;
                            position:absolute; left:0; top:0;
                            
                            &:before {content:''; position:absolute; width:1px; height:150%; background:#3c64b1; top:-150%; left:50%;}
                        }
                        .c_tit{ position:relative; z-index: 1; display:flex; align-items: center; justify-content: center;
                            width:124px; padding-bottom:124px; border-radius: 62px; overflow: hidden; border:1px solid #3c64b1; background:#f0f2f5; text-align: center;
                            span {font-size:16px; font-weight: 700; color:#004098;position:absolute; left:50%; top:50%; transform: translate(-50%, -50%); width:100%; display:inline-block; padding: 10px; box-sizing: border-box;}
                           
                        }
                        .c_cont {margin-top:0px; text-align: left; display:flex; align-items: center; height: 100%;}
                    }
                }
            }
        }
        .company_team {
            margin-top:150px;
            .team_intro {
                .tit {margin-bottom:30px;}
            }
            .team_member { margin-top:90px; padding-bottom: 140px;
                background:#f0f2f5; padding-top:0px;
                .team_list { display:flex; flex-wrap: wrap; justify-content: center; margin-left:-4.5%;
                    li {padding:20px; margin-bottom:80px; min-height: 220px; margin-left:4.5%;
                        width:44%; padding-top:70px; position: relative; border-radius: 30px; background:#fff; padding-bottom:35px;
                        .img_wrap {width:98px; border-radius: 49px; overflow: hidden; position:absolute; top:0px; left:50%; transform: translate(-50%, -50%);
                            filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));
                            img {width:100%;}
                        }
                        .mem_info { text-align: center;
                            .name {font-weight: 700;}
                            .desc {margin-top:8px;}
                            .txt + .txt {margin-top:18px;}
                        }
                        .btn_wrap {text-align: center; position:absolute; left:50%; bottom:20px; margin-left:-12px; 
                            button {width:24px; height:24px; display:inline-block; position:relative;
                                &:after {content:''; position:absolute; top:50%; left:50%; width:18px; height:18px; background:url('../../images/common/icon_linkedin_sm.png'); background-repeat: no-repeat; transform: translate(-50%, -50%);}
                                &:disabled {opacity: 0.4; cursor:default;}
                            }
                        }
                    }
                }
                .btn_box { text-align: center;}
            }
            .career_btn { padding: 50px 0 100px; }
        }
    
        .simplebar-content-wrapper {
            overflow: hidden !important;
        }
        .simplebar-track {
            right:auto; left:0;
        }
    
        .simplebar-scrollbar:before {
            background-color: #004098; border-radius: 0;
        }
        .simplebar-scrollbar.simplebar-visible:before {
            opacity: 1;
        }
        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top:0; bottom:0; left:0px; right:0;    border-radius: 3px;
        }
        .simplebar-track.simplebar-vertical {
            &:before {    border-radius: 3px;
                content:''; width:6px; height:100%; position:absolute; top:0; left:0px; background-color: #d9d9d9;
            }
        }
        .simplebar-track.simplebar-vertical {width:6px; display:none;}
        
    }
    .btn_arrow_down {display:inline-block; border:none; font-size:14px; font-weight: 700; color:#004098; padding-right:17px; position: relative;
        &::after {content:''; position: absolute; right:0; top:50%; margin-top:-4px; width:9px; height:10px;
            background: url('../../images/common/arrow_down.svg'); background-size:100%; background-repeat: no-repeat; 
        }
    }
    
    .en {
        .company { 
            .company_culture {
                .cultrue_wrap {
                    .culture_list {
                        li { width:100%; 
                            .c_tit{ 
                                span {font-size:13px; padding:5px; }
                            }
                        }
                    }
                }
            }
        }
    }


}