.lightfield { color:#000; word-break: keep-all;
    .page_title-section .section-inner {
        background-image:url('../../images/visual/vs_lightfield.jpg'); background-repeat: no-repeat; background-position: center; 
    }


    .lightfield_intro {
        padding-top:170px; padding-bottom:170px; background:#f0f2f5;
        text-align: center;
        .panel-body {margin-top:0px}
        .point_circle:before {
            content: "";z-index: 0;position: absolute;width: 312px;height: 312px;border-radius: 50%;background: #fff;overflow: hidden;left: 50%;top: 50%;margin-top:40px;transform: translate(-50%, -50%);
        }
        .tit {font-size:50px;font-weight: 900; word-break: keep-all;
            span {position:relative;}
        }
        .txt {  position: relative;
            font-size: 18px;font-weight: 400; word-break: keep-all; line-height: 1.5;
        }
    }


    .sec_title { font-size:50px; font-weight: 900; line-height: 1.30; }
    .sub_title { font-size:24px; font-weight: 900; }
    .sec_text { font-size:18px; font-weight: 400; line-height: 1.58;  }
    .lf_info { padding:96px 50px 0px;
        .lf_cont_wrap {
            .cont_inner {display:flex; flex-wrap: wrap; align-items: center;
                &.type1 { margin-bottom:157px;  align-items: flex-end; flex-direction: row-reverse;
                    .cont_box {
                        width:43.6%; padding-right:30px;
                        .sub_title_desc {font-size:18px; font-weight: 700; margin-bottom:15px;}
                        .sec_title {margin-bottom:30px;  }
                        .sub_title {margin-bottom:50px; }
                    }
                    .video_wrap {
                        width:56.4%; border-radius: 30px; overflow: hidden;
                        video {width:100%;}
                    }
                }
                &.type2 {margin-bottom:96px;
                    &:last-child{ margin-bottom: 0;}
                     .video_wrap { width:40%;border-radius: 30px; overflow: hidden;
                        video {width:100%;}
                    }
                     .cont_box {
                        width:60%; padding-left:65px;
                        .sub_title_desc {font-size:18px; font-weight: 700; margin-bottom:10px;}
                        .sub_title {margin-bottom:36px;  }
                     }
                }
            }
        }
    }
    .lf_component {
        .sec_title {text-align: center;}
        
    }
    .lf_comp_key { display:flex; flex-wrap :wrap; justify-content: space-between; margin-top:48px;
        li { width:calc(25% - 22px);  position:relative; 
            // &:after {content:''; position: absolute; top:100px; left:-26px; width:26px; height:11px; 
            //     background:url('../../images/common/icon_key_arrow.png'); background-size:100%; background-repeat: no-repeat;
            // }
            &:first-child:after {display:none;}
            .img_wrap { padding-bottom: 64.3%;
                
                > div ,img, video{width:100%; position:absolute; top:0; left:0;}
            }
            .key_cont { text-align: center;
                margin-top:15px;
                .key_tit { font-size:24px; font-weight: 900; }
                .key_txt {font-size:18px; font-weight: 400; margin-top:23px;}
            }
        }
    }
    .lf_solution { 
        padding:145px 0 0px;
        .sol_header { width:58%; padding-bottom:80px;
            .sec_title {margin-bottom:30px;}
        }
        .lf_sol_wrap {display:flex; flex-wrap: nowrap; position: relative;
            .sol_video { position: relative;
                // .swiper {transform: scale(1.01);}
                &:after {content:''; position: absolute; top:0; left:0; width:100%; height:100%; background:transparent; z-index: 111; }
                width:55.736%; border-radius: 30px; overflow:hidden; background: #000;
                video {width:100%; height:426px; }
                .img_wrap { object-fit: cover; height:426px;
                    img, video {width:100%; height:100%; }
                    video {}
                }
            }
            .sol_acd { 
                width:44%;padding-left:30px;
                .sol_item { border-bottom:1px solid #c3cbcd;
                    .item_head { border-top:1px solid #c3cbcd; position:relative;
                        button {
                            display:block; line-height: 1.4; font-weight: 700; font-size: 18px;padding:15px 12px; width:100%; text-align: left;
                        }
                        &:after {content:''; width:16px; height:8px; 
                            background:url('../../images/common/acord_arrow.png'); 
                            display:block; position:absolute; top:50%; right:12px; margin-top:-4px; transform: rotate(180deg); 
                           // transition: all .2s;
                        }
                    }
                    .item_body { padding:12px 12px 38px; 
                        display:none; 
                        span {font-size:18px; font-weight: 400; color:#000; line-height: 1.55; min-height:145px; display:inline-block;}
                    }
                    &.on {
                        .item_head { border-top:1px solid #c3cbcd;
                            button { color:#004098; }
                            &:after {transform: rotate(0deg); }
                        }
                        .item_body {
                            // display:block;
                        }
                    }
                }
            }
        }
        .btn_wrap {
            margin-top:130px;
        }
    }
    .lf_t1 { margin:94px 0; text-align: center; background:#f0f2f5; padding:124px 0;
        .tit {margin-bottom: 36px;}
        
        .tit {font-size:50px;font-weight: 900; word-break: keep-all;
            
        }
        .txt {  position: relative; max-width: 870px; margin:0 auto;
            font-size: 18px;font-weight: 400; word-break: keep-all; line-height: 1.5;
        }

    }
}

.lottie_wrap{ 
    svg { display: none;
        &:first-child {display:block;}
    }

}














@media all and (max-width:$G-wMobile)  {

    .lightfield { 
        .lightfield_intro {
            padding-top:80px; padding-bottom:80px; 
            .point_circle:before {
                content: "";z-index: 0;position: absolute;width: 171px;height: 171px;border-radius: 50%;background: #fff;overflow: hidden;left: 50%;top: 50%;margin-top:40px;transform: translate(-50%, -50%);
            }
            .tit {font-size:24px;}
            .txt {  position: relative;font-size: 14px;}
        }
    
    
        .sec_title { font-size:24px; font-weight: 900; line-height: 1.30; }
        .sub_title { font-size:18px; font-weight: 700; }
        .sec_text { font-size:14px; font-weight: 400; line-height: 1.58;  }
        .lf_info { padding:96px 0px 0px;
            .lf_cont_wrap {
                .cont_inner {display:block;
                    &.type1 { margin-bottom:74px;  align-items: flex-end; flex-direction: row-reverse;
                        .cont_box { margin-top:25px;
                            width:auto; padding-right:30px;
                            .sub_title_desc {font-size:14px; font-weight: 700; margin-bottom:15px;}
                            .sec_title {margin-bottom:30px;  }
                            .sub_title {margin-bottom:10px; }
                        }
                        .video_wrap {
                            width:auto; border-radius: 20px; overflow: hidden;
                            video {width:100%;}
                        }
                    }
                    &.type2 {margin-bottom:74px;
                        &:last-child{ margin-bottom: 0;}
                         .video_wrap { width:auto;border-radius: 20px; overflow: hidden;
                            video {width:100%;}
                        }
                         .cont_box { margin-top:25px;
                            width:auto; padding-left:0px;
                            .sub_title_desc {font-size:14px; font-weight: 700; margin-bottom:10px;}
                            .sub_title {margin-bottom:18px;  }
                         }
                    }
                }
            }
        }
        .lf_component {
            .lf_comp_wrap {display:flex; flex-wrap: nowrap;
                .comp_visual {width:52%;}
                .comp_list { width:48%;
                    .comp_item { background:#fff; border-radius: 30px; overflow:hidden; padding:55px 47px; position:relative;
                        display:flex; flex-wrap: nowrap; align-items: center; margin-top:30px;
                        &:first-child {margin-top:0;}
                        .comp_type {  text-align: center;
                            .comp_cate { width:160px; border-bottom:1px solid #c3cbcd; padding-bottom:10px; font-size:24px; font-weight: 900; color:#004098; }
                            .comp_title { width:160px; padding-top:10px; font-weight: 900; font-size:24px; letter-spacing: -1px;}
                        }
                        .sec_text { padding-left:40px;}
                    }
                }
            }
        }
        .lf_comp_key { display:block; margin:15px auto 0; max-width: 500px;
            li { width:auto;  position:relative;  border-top: 1px solid #F0F2F5; padding-bottom:15px; padding-top:15px;
                &:first-child {border-top:none;}
                // &:after {content:''; position: absolute; top:100px; left:-26px; width:26px; height:11px; 
                //     background:url('../../images/common/icon_key_arrow.png'); background-size:100%; background-repeat: no-repeat;
                // }
                &:first-child:after {display:none;}
                .img_wrap { padding-bottom: 64.3%; position: relative;
                    
                    > div ,img, video{width:100%; position:absolute; top:0; left:0;}
                }
                .key_cont { text-align: center;
                    margin-top:10px;
                    .key_tit { font-size:18px; font-weight: 700; }
                    .key_txt {font-size:14px; font-weight: 400; max-width: 200px; margin:0 auto; margin-top:12px;}
                }
            }
        }
        .lf_solution { 
            padding:145px 0 0px;
            .sol_header { width:auto; padding-bottom:0;
                .sec_title {margin-bottom:34px; text-align: center;}
            }
            .lf_sol_wrap {display:block; flex-wrap: nowrap; position: relative;
                .sol_video { position: relative;
                    &:after {content:''; position: absolute; top:0; left:0; width:100%; height:100%; background:transparent; z-index: 111; display:none;}
                    width:auto; border-radius: 30px; overflow:hidden; background: #000;
                    
                    .img_wrap { object-fit: cover; height:auto; padding-bottom:56.134%;
                        img, video {width:100%; height:100%; position: absolute; top:0; left:0; }
                        video {}
                    }
                }
                .sol_acd { 
                    width:auto;padding-left:0; margin-top:21px;
                    .sol_item { border-bottom:1px solid #c3cbcd;
                        .item_head { border-top:1px solid #c3cbcd; position:relative;
                            button {
                                display:block; line-height: 1.4; font-weight: 700; font-size: 14px;padding:15px 12px; width:100%; text-align: left;
                            }
                            &:after {content:''; width:16px; height:8px; 
                                background:url('../../images/common/acord_arrow.png'); 
                                display:block; position:absolute; top:50%; right:12px; margin-top:-4px; transform: rotate(180deg); 
                               // transition: all .2s;
                            }
                        }
                        .item_body { padding:12px 12px 24px; 
                            display:none; 
                            span {font-size:14px; font-weight: 400; color:#000; line-height: 1.55; min-height:auto; display:inline-block;}
                        }
                        &.on {
                            .item_head { border-top:1px solid #c3cbcd;
                                button { color:#004098; }
                                &:after {transform: rotate(0deg); }
                            }
                            .item_body {
                                // display:block;
                            }
                        }
                    }
                }
            }
            .btn_wrap {
                margin-top:40px; margin-bottom:45px;
            }
        }
        .lf_t1 { margin:80px 0; text-align: center; background:#f0f2f5; padding:80px 0;
            .tit {margin-bottom: 33px;}
            
            .tit {font-size:24px;font-weight: 900; word-break: keep-all;
                
            }
            .txt {  position: relative; max-width: 870px; margin:0 auto;
                font-size: 14px;font-weight: 400; word-break: keep-all; line-height: 1.5;
            }
    
        }
    }
    
    .lottie_wrap{ 
        svg { display: none;
            &:first-child {display:block;}
        }
    
    }



}