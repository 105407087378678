.prd-dhaula-pre {
  line-height: 1.5;
  word-break: keep-all;
  .page-product {
    &.page_intro {
      background: #222;
      color: #fff;
      padding-top: 100px;
      padding-bottom: 200px;
      text-align: center;
      .basic-section {
        background-color: transparent;
      }
      .order_header_tit {
        font-size: 32px;
        font-weight: 900;
        span {
          display: block;
          &.sub_tit {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .order_header_img {
        margin: 100px 0;
        img,
        video {
          max-width: 800px;
          width: 100%;
        }
      }
      .order_header_img2 {
        margin: 100px 0;
        img,
        video {
          max-width: 800px;
          width: 100%;
        }
      }
      .order_header_cont {
        font-size: 24px;
      }

      @media all and (max-width: $G-wMobile) {
        padding-top: 50px;
        padding-bottom: 100px;

        .order_header_tit {
          font-size: 20px;
        }
        .order_header_img {
          margin: 50px 0;
        }
        .order_header_img2 {
          margin: 50px 0;
        }
        .order_header_cont {
          font-size: 16px;
        }
      }
    }
    &.page0 {
      background: #222;
      padding: 100px 0;
      color: #fff;
      .basic-section {
        background-color: transparent;
      }
      .dhaula_intro {
        .intro_header {
          text-align: center;
          margin-bottom: 50px;
          .title {
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          .sub_tit {
            font-size: 18px;
          }
        }
      }
      .intro_colswrap {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 990px;
        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
        }
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
        }

        .intro_swiper_wrap {
          width: 54%;
          padding-right: 30px;
          margin-bottom: 40px;

          .prd_swiper_img {
            .swiper-slide {
              img {
                width: 100%;
              }
            }
          }
          .prd_swiper_thumb {
            .swiper-slide {
              padding: 2px;
              .img_box {
                border: 1px solid #666;
                transition: 0.2s all;
                position: relative;
              }
              img {
                width: 100%;
                cursor: pointer;
              }
              &.swiper-slide-thumb-active .img_box {
                border-color: #ccc;
              }
            }
          }
        }
        .intro_cont {
          width: 45%;
          padding-left: 30px;
          color: #fff;
          margin-top: 10px;
          .prd_tit {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .prd_txt {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          .prd_link_list {
            .prd_link_item {
              margin-top: 10px;
              a {
                text-decoration: underline;
                display: inline-block;
                padding-left: 30px;
                position: relative;
                &:before {
                  content: "chevron_right";
                  position: absolute;
                  left: 0;
                  top: -4px;
                  font-family: "Material Icons";
                  font-weight: normal;
                  font-style: normal;
                  font-size: 24px;
                }
              }
            }
            .prd_notlink_item {
              margin-top: 10px;
              position: relative;
              padding-left: 15px;
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #fff;
              }
            }
          }
          .order_date {
            font-size: 20px;
            margin-top: 85px;
            margin-bottom: 15px;
            
          }
          .order_icon {
            img { max-width: 80%;}
          }
          .link_order {
            padding: 15px 40px;
            background: #7580bd;
            color: #fff;
            font-size: 24px;
            text-align: center;
            min-width: 400px;
            transition: 0.2s all;
            &:hover {
              background: #004098;
            }
          }

          .desc_txt {
            font-size: 13px;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }

      .intro_txt {
        margin-top: 40px;
        .txt {
          color: #fff;
          text-align: center;
          margin: 0 auto;
          width: 75%;
          line-height: 1.7;
          font-size: 24px;
          max-width: 930px;
          word-break: keep-all;
        }
      }
    }

    @media all and (max-width: $G-wMobile) {
      .basic-section {
        padding: 0 32px;
      }
      &.page0 {
        padding: 30px 0;

        .dhaula_intro {
          .intro_header {
            margin-bottom: 0;

            .title {
              font-size: 24px;
              margin-bottom: 10px;
            }

            .sub_tit {
              font-size: 15px;
            }
          }
        }

        .intro_colswrap {
          display: block;
          margin: 0 auto;
          width: auto;
          padding: 0 32px;

          .swiper-button-prev,
          .swiper-container-rtl .swiper-button-next,
          .swiper-button-next,
          .swiper-container-rtl .swiper-button-prev {
            transform: scale(0.5);
          }

          .intro_swiper_wrap {
            width: auto;
            padding-right: 0;
            margin-bottom: 40px;
          }

          .intro_cont {
            width: auto;
            padding: 0;
            margin-top: 10px;

            .prd_tit {
              font-size: 13px;
              margin-bottom: 5px;
            }

            .prd_txt {
              font-size: 18px;
              margin-bottom: 20px;
            }

            .prd_link_list {
              .prd_link_item {
                margin-top: 5px;
                font-size: 14px;

                a {
                  padding-left: 23px;

                  &:before {
                    content: "chevron_right";
                    position: absolute;
                    left: 0;
                    top: -4px;
                    font-family: "Material Icons";
                    font-weight: normal;
                    font-style: normal;
                    font-size: 20px;
                  }
                }
              }
            }

            .order_date {
              font-size: 20px;
              margin-top: 20px;
            }

            .link_order {
              padding: 10px 40px;
              background: #004098;
              color: #fff;
              font-size: 20px;
              text-align: center;
              width: 100%;
              min-width: auto;
            }
          }
        }

        .intro_txt {
          margin-top: 30px;
          padding: 0 32px;

          .txt {
            font-size: 16px;
            text-align: left;
            margin: 0 auto;
            width: auto;
          }
        }
      }
    }
  }

  .dhaula_release {
    margin-top: 200px;
    word-break: keep-all;
    .release_header {
      text-align: center;
      .title {
        font-size: 32px;
        font-weight: 700;
      }
      .sub_tit {
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
      }
    }
    .release_cont {
      text-align: center;
      margin-top: 70px;
      .video_box {
        video {
          max-width: 700px;
          width: 100%;
        }
      }
      .txt {
        margin: 70px auto;
        max-width: 900px;
        line-height: 1.7;
        font-size: 24px;
      }
    }
    .release_design {
      margin-top: 200px;
      .prd_imgs {
        display: flex;
        justify-content: center;
        .img_box {
          img {
            width: 100%;
          }
        }
      }
      .icon_img {
        text-align: center;
        img {
          width: 100%;
          max-width: 256px;
        }
      }
      .design_txt {
        margin: 70px auto;
        max-width: 600px;
        text-align: center;
        line-height: 1.7;
      }
    }
  }

  @media all and (max-width: $G-wMobile) {
    .dhaula_release {
      margin-top: 100px;
      .release_header {
        text-align: center;
        .title {
          font-size: 24px;
        }
        .sub_tit {
          font-size: 18px;
          margin-top: 10px;
        }
      }
      .release_cont {
        margin-top: 30px;
        .video_box {
          video {
            max-width: 700px;
            width: 100%;
          }
        }
        .txt {
          margin: 70px auto;
          max-width: 700px;
          line-height: 1.7;
          font-size: 14px;
        }
      }
      .release_design {
        margin-top: 100px;
        .prd_imgs {
          display: block;
          .img_box {
            margin-bottom: 30px;
            img {
              width: 100%;
            }
          }
        }
        .icon_img {
          text-align: center;
          img {
            width: 100%;
            max-width: 256px;
          }
        }
        .design_txt {
          margin: 70px auto;
          max-width: 600px;
          text-align: center;
          line-height: 1.7;
        }
      }
    }
  }
  .page2 {
    padding-top: 200px;
    background: #fff;
    padding-bottom: 200px;
    .basic-section {
      background-color: transparent;
    }
  }

  .feather_title {
    text-align: center;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 200px;
    margin-top: 200px;
  }
  .feature_list {
    display: flex;
    justify-content: space-between;
    word-break: keep-all;
    .feature_item {
      width: 30%;
      background: #fff;
      border-radius: 50px;
      padding: 180px 30px 100px;
      text-align: center;
      position: relative;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
      .round_box {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 0;
        color: #fff;
        .inner_box {
          width: 100%;
          padding-bottom: 100%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 50%;
          background-color: #004098;
        }
        span {
          position: absolute;
          font-size: 32px;
          line-height: 150px;
          left: 0;
          width: 100%;
          text-align: center;
          transform: translateY(-50%);
          top: 50%;
        }
        &.rb1 .inner_box {
          background-color: #558bcb;
        }
        &.rb2 .inner_box {
          background-color: #7580bd;
        }
      }
      .tit {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      .txt {
        font-size: 18px;
      }
    }
  }
  .tech_title {
    text-align: center;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 100px;
  }
  .tech_list {
    width: 960px;
    margin: 0 auto;
    .text_item {
      position: relative;
      padding-right: 55%;
      margin-top: 50px;
      &:first-child {
        margin-top: 0;
      }
      .img_box {
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        .box_inner {
          width: 100%;
          padding-bottom: 65%;
          img,
          video {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
          .tech_img_0 {
            transform: scale(1.2) translateY(8%);
          }
          .tech_img_1 {
            transform: translateY(-7%);
          }
          .tech_img_2 {
            transform: translateY(-5.5%);
          }
        }
      }
      .cont_box {
        position: absolute;
        left: 55%;
        top: 50%;
        padding-left: 20px;
        transform: translateY(-50%);
        .tit {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 20px;
        }
        .txt {
          font-size: 18px;
        }
      }
      &.item1 {
        padding-right: 0;
        padding-left: 55%;
        .cont_box {
          position: absolute;
          right: 55%;
          left: auto;
          top: 50%;
          padding-left: 0px;
          padding-right: 20px;
          transform: translateY(-50%);
          text-align: right;
        }
      }
    }
  }

  @media all and (max-width: $G-wMobile) {
    .page2 {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .feather_title {
      font-size: 24px;
      margin-bottom: 120px;
      margin-top: 120px;
    }
    .feature_list {
      display: block;
      margin-bottom: 100px;
      .feature_item {
        width: auto;
        border-radius: 25px;
        padding: 100px 15px 50px;
        text-align: center;
        position: relative;
        margin-top: 130px;
        .round_box {
          width: 150px;
          position: absolute;
          left: 50%;
          top: 0;
          color: #fff;
          .inner_box {
            width: 100%;
            padding-bottom: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            border-radius: 50%;
            background-color: #004098;
          }
          span {
            position: absolute;
            font-size: 24px;
            line-height: 150px;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
            top: 50%;
          }
          &.rb1 .inner_box {
            background-color: #558bcb;
          }
          &.rb2 .inner_box {
            background-color: #7580bd;
          }
        }
        .tit {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .txt {
          font-size: 14px;
        }
      }
    }
    .tech_title {
      text-align: center;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 50px;
    }
    .tech_list {
      width: auto;
      margin: 0 auto;
      .text_item {
        position: relative;
        padding-right: 0;
        margin-top: 50px;
        &:first-child {
          margin-top: 0;
        }
        .img_box {
          position: relative;
          border-radius: 15px;
          overflow: hidden;
          .box_inner {
            width: 100%;
            img,
            video {
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
            .tech_img_0 {
              transform: scale(1.2) translateY(8%);
            }
            .tech_img_1 {
              transform: translateY(-7%);
            }
            .tech_img_2 {
              transform: translateY(-5.5%);
            }
          }
        }
        .cont_box {
          position: relative;
          left: auto;
          top: auto;
          padding-left: 0px;
          transform: translateY(0%);
          margin-top: 20px;
          .tit {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 5px;
          }
          .txt {
            font-size: 14px;
          }
        }
        &.item1 {
          padding-right: 0;
          padding-left: 0;
          .cont_box {
            position: relative;
            right: auto;
            left: auto;
            top: auto;
            padding-left: 0px;
            padding-right: 0px;
            transform: translateY(0%);
            text-align: left;
          }
        }
      }
    }
  }

  .sol_header {
    margin-top: 200px;
    .sec_title {
      font-size: 32px;
      margin-bottom: 100px;
      text-align: center;
      font-weight: 900;
    }
  }
  .lf_sol_wrap {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    .sol_video {
      position: relative;
      .swiper {
        height: 100%;
      }
      // .swiper {transform: scale(1.01);}
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 111;
      }
      width: 49%;
      border-radius: 30px;
      overflow: hidden;
      background: #000;
      video {
        width: 100%;
        height: 426px;
      }
      .img_wrap {
        object-fit: cover;
        height: 426px;
        img,
        video {
          width: 100%;
          height: 100%;
          transform: scale(1.000001) translateY(-50%);
          position: absolute;
          top: 50%;
        }
      }
    }
    .sol_acd {
      width: 50%;
      padding-left: 30px;
      .sol_item {
        border-bottom: 1px solid #c3cbcd;
        .item_head {
          border-top: 1px solid #c3cbcd;
          position: relative;
          button {
            display: block;
            line-height: 1.4;
            font-weight: 700;
            font-size: 18px;
            padding: 15px 12px;
            width: 100%;
            text-align: left;
          }
          &:after {
            content: "";
            width: 16px;
            height: 8px;
            background: url("../../images/common/acord_arrow.png");
            display: block;
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -4px;
            transform: rotate(180deg);
            // transition: all .2s;
          }
        }
        .item_body {
          padding: 12px 12px 20px;
          display: none;
          span {
            font-size: 18px;
            font-weight: 400;
            color: #000;
            line-height: 1.55;
            display: inline-block;
          }
        }
        &.on {
          .item_head {
            border-top: 1px solid #c3cbcd;
            button {
              color: #004098;
            }
            &:after {
              transform: rotate(0deg);
            }
          }
          .item_body {
            // display:block;
          }
        }
      }
    }
  }

  @media all and (max-width: $G-wMobile) {
    .full-section .center-box {
      padding: 0;
    }
    .sol_header {
      margin-top: 0px;
      .sec_title {
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 900;
      }
    }
    .lf_sol_wrap {
      display: block;
      flex-wrap: nowrap;
      position: relative;
      .sol_video {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          z-index: 111;
          display: none;
        }
        width: auto;
        border-radius: 30px;
        overflow: hidden;
        background: #000;

        .img_wrap {
          object-fit: cover;
          height: auto;
          padding-bottom: 56.134%;
          img,
          video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          video {
          }
        }
      }
      .sol_acd {
        width: auto;
        padding-left: 0;
        margin-top: 21px;
        .sol_item {
          border-bottom: 1px solid #c3cbcd;
          .item_head {
            border-top: 1px solid #c3cbcd;
            position: relative;
            button {
              display: block;
              line-height: 1.4;
              font-weight: 700;
              font-size: 14px;
              padding: 15px 12px;
              width: 100%;
              text-align: left;
            }
            &:after {
              content: "";
              width: 16px;
              height: 8px;
              background: url("../../images/common/acord_arrow.png");
              display: block;
              position: absolute;
              top: 50%;
              right: 12px;
              margin-top: -4px;
              transform: rotate(180deg);
              // transition: all .2s;
            }
          }
          .item_body {
            padding: 12px 12px 24px;
            display: none;
            span {
              font-size: 14px;
              font-weight: 400;
              color: #000;
              line-height: 1.55;
              min-height: auto;
              display: inline-block;
            }
          }
          &.on {
            .item_head {
              border-top: 1px solid #c3cbcd;
              button {
                color: #004098;
              }
              &:after {
                transform: rotate(0deg);
              }
            }
            .item_body {
              // display:block;
            }
          }
        }
      }
    }
  }
}

.order_title {
  padding-top: 150px;
  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 50px;
  }
}
.order_wrap {
  display: flex;
  justify-content: space-between;
  &.sended .order_img {
    padding-bottom: 250px;
    margin: 0 auto;
  }
  .order_img {
    width: 50%;
    padding-top: 30px;
    .video_wrap {
      overflow: hidden;
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        background: #f0f2f5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        left: auto;
      }
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background: #f0f2f5;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
      video {
        width: 100%;
        transform: scale(1.001);
      }
    }
    .cont_box {
      .tit {
        text-align: center;
        font-size: 24px;
        margin-top: 30px;
      }
    }
  }

  .order_modal {
    width: 50%;
    padding: 20px;
    position: relative;
    padding-bottom: 200px;
    .modal_close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 0;
      &::after {
        content: "close";
        font-size: 40px;
        color: #000;
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
      }
    }
    h2 {
      text-align: center;
    }

    .consulting_box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .consult_cont {
        margin-top: 20px;
        width: 100%;
        padding-left: 150px;
        position: relative;

        &:first-child {
          margin-top: 0;
        }
        label.label_text,
        .label {
          font-size: 18px;
          color: #000;
          font-weight: 700;
          display: block;
          padding: 15px;
          position: absolute;
          left: 0;
          top: 0;
        }
        input[type="text"],
        input[type="number"] {
          background: #fff;
          border: 0;
          color: #000;
          width: 100%;
          font-size: 18px;
        }
        input[type="radio"] {
          background: #fff;
          border: 0;
          color: #000;
          font-size: 18px;
        }
        input[type="radio"] + label {
          font-size: 18px;
          color: #000;
          font-weight: 500;
        }
        .must {
          color: #f00;
          display: inline-block;
          padding-left: 4px;
        }
        textarea {
          height: 159px;
          padding: 11px 17px;
          font-weight: 500;
        }
        .sel_wrap {
          padding: 15px 17px 16px;
        }
        .trp.radio-box {
          padding-left: 30px;
          min-height: 24px;
          & + .trp.radio-box {
            margin-left: 70px;
          }
          label {
            font-size: 18px;
            cursor: pointer;
          }
          input[type="radio"] + i {
            top: 50%;
            margin-top: -10px;
            top: 50%;
            &:after {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .btn_wrap {
        margin-top: 30px;
        text-align: right;
        width: 100%;
        .btn {
          min-width: 150px;
        }
      }
      .msg {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        color: #000;
        padding-right: 10px;
      }
    }
  }
}

.page-product-order {
  background: #f0f2f5;
  .basic-section {
    background: transparent;
  }
}

@media all and (max-width: $G-wMobile) {
  .order_title {
    padding-top: 100px;
    h2 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 0px;
    }
  }
  .order_wrap {
    display: block;
    &.sended .order_img {
      padding-bottom: 0px;
    }
    .order_img {
      width: auto;
      padding-top: 30px;
      .video_wrap {
        overflow: hidden;
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          background: #f0f2f5;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          left: auto;
        }
        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background: #f0f2f5;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
        }
        video {
          width: 100%;
          transform: scale(1.001);
        }
      }
      .cont_box {
        .tit {
          text-align: center;
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }

    .order_modal {
      width: auto;
      padding: 0px;
      padding-bottom: 50px;
      padding-top: 50px;

      .consulting_box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .consult_cont {
          margin-top: 20px;
          width: 100%;
          padding-left: 0px;

          &:first-child {
            margin-top: 0;
          }
          label.label_text,
          .label {
            font-size: 14px;
            color: #000;
            font-weight: 700;
            display: block;
            padding: 15px 0;
            position: relative;
            left: 0;
            top: 0;
          }
          input[type="text"] {
            background: #fff;
            border: 0;
            color: #000;
            width: 100%;
            font-size: 14px;
          }
          input[type="radio"] {
            background: #fff;
            border: 0;
            color: #000;
            font-size: 14px;
          }
          input[type="radio"] + label {
            font-size: 14px;
            color: #000;
            font-weight: 500;
          }
          .must {
            color: #f00;
            display: inline-block;
            padding-left: 4px;
          }
          textarea {
            height: 185px;
            padding: 11px 17px;
            font-weight: 500;
            font-size: 14px;
          }
          .sel_wrap {
            padding: 0px 17px 16px;
          }
          .trp.radio-box {
            padding-left: 30px;
            min-height: 24px;
            display: block;
            & + .trp.radio-box {
              margin-left: 0px;
              margin-top: 10px;
            }
            label {
              font-size: 14px;
              cursor: pointer;
            }
            input[type="radio"] + i {
              top: 50%;
              margin-top: -10px;
              top: 50%;
              &:after {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .btn_wrap {
          margin-top: 30px;
          text-align: left;
          width: 100%;
          margin-bottom: 0px;
          .btn {
            width: 100%;
          }
        }
        .msg {
          display: inline-block;
          font-size: 14px;
          font-weight: 700;
          color: #000;
          padding-right: 10px;
        }
      }
    }
  }

  .page-product-order {
    padding-bottom: 100px;
  }
}
