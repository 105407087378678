/* style_common.scss */
html,body,.wrapper { min-height:100%; width: 100%; }
body { 
  min-height: 100vh; 
  min-height: calc(var(--vh, 1vh) * 100);
 }
#container { min-height: 100%; }
#contents  {  
  //min-height: calc(100vh); 
  //min-height: calc(var(--vh, 1vh) * 100);
}
.body-inner { width:$G-width; margin: 0 auto; position: relative; }


/* only */
.only-pc { display: block; } .only_ib-pc { display: inline-block; }
.only-mb { display: none;  } .only_ib-mb { display: none; } 
@media all and (max-width:$G-wMobile)  {
  .only-pc { display: none; } .only_ib-pc { display: none; }
  .only-mb { display: block;  } .only_ib-mb { display: inline-block; } 
}

.colorB { color: $colorB; } // 강조진하게
.colorB2 { color: $colorB2; } // 강조진2하게
.colorP { color: $colorP; } // 포인트 컬러
.colorD { color: $colorD; } // 기본 컬러
.colorS { color: $colorS; } // 서브컬러
.colorL { color: $colorL; } // 저체도
.colorE { color: $colorE; } // 에러


/* ========================== 컨텐츠 간격  */
.mt_st { margin-top: 50px;}  // 섹션
.mt_ct { margin-top: 40px;}  // 컨텐츠
.mt_it { margin-top: 30px;}  // item
.mt_tb { margin-top: 20px;}  // title, body 간격
.mt_p  { margin-top: 10px;}  // 문단



/* ==========================  비율  */
.img-ratio {position: relative; width: 100%; height: 0; 
  padding-bottom: 100%; text-align: center;  
}

/* ==========================  정열  */
.img-align { position: absolute; right: 0; left: 0;
  vertical-align: middle;  width: auto;  height: 100%;
  text-align: center; font-size: 0; overflow: hidden;
  &::before { content: ''; display: inline-block;  height: 100%; width: 0;
    vertical-align: middle; font-size: 0;
  }
  img { display: inline-block; 
    height: auto; width: auto; max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
}


/* ==========================  섹션 */

.blind-text {font-size:0px; color:transparent; text-indent: -9999px;  display: block; overflow: hidden; line-height: 0; }

.flex_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wrap_padding, .container {
    padding-left: 40px;
    padding-right: 40px;
}

.full-section .center-box {
    min-height: 180px;
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.basic-section {
    background:#fff;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin-left: auto;
    margin-right: auto;
}
@media all and (max-width:$G-wMobile)  {
  .full-section .center-box {
      padding: 0 32px;
  }
  
  .basic-section {
    padding: 0 32px;
  }
}

/* 섹션 공통 */
.basis-section{ margin-top: 60px; width: 100%; max-width: 1420px; padding:0 50px;
  margin-left: auto; margin-right: auto;
  .section-inner {position: relative; }
  .pc-full { margin-left: -60px; width: calc( 100% + 120px );}
  .mb-full { }
  &.tab-cont-section {margin-top: 0;}
  &.edit_view-section {margin-top: 0;}

}
@media all and (max-width:$G-wMobile)   {
  .basis-section{ margin-top: 20px; padding: 0 25px;}
  .pc-full { margin-left: -25px; width: calc( 100% + 50px );}
  .mb-full { margin-left: -25px; width: calc( 100% + 50px );}
}
section[class$="-section"] { 
  .section-inner.full_size  { 
   
  }
}


/* 페이지 타이틀 */
.page_title-section { 
  .section-inner {   background-color: #f6f6f6; height:450px;  }
  .center-box { min-height: 180px; width: 100%; margin: 0 auto;
    max-width: 1440px; padding: 0 50px;
    display: flex; flex-direction: column; justify-content: center; 
    height: 450px;
  }
  .page_title { padding-bottom:115px;
    span {vertical-align: middle; font-size:50px; color:#fff; font-weight: 900; line-height: 1.48;}
  }
  @media all and (max-width:$G-wMobile)  {
    .section-inner {   height:140px; background-size: cover; }
    .center-box { min-height: auto;
      display: flex; flex-direction: column; justify-content: center; 
      height: 140px;  padding: 0 16px;
    }
    .page_title { padding-bottom:0;
      span {vertical-align: middle; font-size:24px; color:#fff; font-weight: 900; line-height: 1.48;}
    }
  }


 
}


/* ==========================  content-panel (type-컨텐츠이름) */
.content-panel{  
  >.panel-header { padding: 35px 0;
    .title {   
      span { color:$colorB; line-height: 40px; }
    }
    .desc { margin-top:20px;}
    
    .ab-r { font-size: 0;}
  }
  >.panel-body {   } 
  >.panel-footer {   } 
  @media all and (max-width:$G-wMobile)  {
    >.panel-header { padding: 15px 0;
      .title {   
        span { line-height: 30px; }
      }
      .desc { margin-top:10px;}
    }
  }





}


.mCustomScrollBox { padding-right:0px;} 
.mCustomScrollbar.pr0 > .mCustomScrollBox {padding-right:0px;}









.js-fadeIn { opacity: 0; transform: translateY(100px); transition: opacity .5s cubic-bezier(0, 0.5, 0.5, 1) .2s, transform .5s cubic-bezier(0, 0.5, 0.5, 1) .2s;
  &.on {
    opacity: 1; transform: translateY(0px); 
  }
}



/* 토스트에디터-유튜브 플러그인 시작 */
.toast-ui-youtube-plugin-wrap{
  max-width:500px;
  margin-left:auto;
  margin-right:auto;
  position:relative;
}

.toast-ui-youtube-plugin-wrap::before{
  content:"";
  display:block;
  padding-top:calc(100% / 16 * 9);
}

.toast-ui-youtube-plugin-wrap > iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
/* 토스트에디터-유튜브 플러그인 끝 */


.point_circle { position:relative;
  &:before {content:''; z-index:-1; position:absolute; width:104px; height:104px; border-radius: 50%; background:#f0f2f5; overflow: hidden; left:-52px; top:-18px;}
  &.white_circle:before {background:#fff;}
}

.mb_only { display:none;}
@media all and (max-width:$G-wMobile)  {
  .point_circle { 
    &:before {left:-16px; top:-20px;}
  }
  .pc_only { display:none; }
  .mb_only {display:block;}
}













.list_container {
  &.board_list {
    .list_header{margin-top:70px;  min-height: 80px; }
    .list_body { padding-top:0;
      .cs_list {  display:flex; flex-wrap: wrap;  margin-left:0px; justify-content: flex-start;
        > li {width:100%; padding-left:0px; padding-bottom:0;    border-bottom: 1px solid #C3CBCD; margin-bottom: 0;
        .cs_item {
            .img_wrap { display:none;}
            .cont_wrap {
                .title {
                    font-size:20px; font-weight: 500; color:#000; margin-top:40px; margin-bottom:40px; padding-left:150px;
                }
                .category {position: absolute; left:20px; color:#555; line-height: 26px;}
                .txt {
                    display:none;
                }
            }
        }
      }
    }
  }
}

  .list_header{ margin-top:170px; padding-bottom:30px; border-bottom:1px solid #C3CBCD; position:relative; min-height: 60px;
      .list_tag_wrap { 
          .cs_tag { display:flex; flex-wrap: wrap; justify-content: flex-start;
              .tag_item { margin-right:12px; padding:10px;
                  button {
                      border:none; color:#004098; font-weight: 400; 
                  }
                  &.on button {font-weight: 700; text-decoration: underline;}
              }
          }
      }
      .list_search { background: #F6F6F6; border-radius: 40px; height:40px; overflow: hidden; width:420px; 
          position:absolute; top:0; right:0;
          input { background:none; font-size:18px; height:40px; padding: 0 50px 0 20px; border:none;}
          .btn_search {
              display:block; width:40px; height:40px; border:none; background:none; position:absolute; top:0; right:10px; 
              &:after {content:''; position:absolute; width:18px; height:18px; top:50%; left:50%; margin-top:-9px; margin-left:-9px; background:url('../../images/common/icon_search.png'); background-size:100%; background-repeat: no-repeat; }
          }
      }
  }
  .list_body { padding-top:60px;border-bottom:1px solid #C3CBCD; 
      .cs_list { display:flex; flex-wrap: wrap;  margin-left:-30px; justify-content: flex-start;
          > li {width:33.333%; padding-left:30px; padding-bottom:80px; position: relative; margin-bottom:40px;
            &.unopened {
              .cs_item {
                opacity: 0.2;
              }
            }
          }
          .cs_item {
            &:hover {
              .img_wrap img {
                transform: scale(1.1);
              }
            }
              .img_wrap { border-radius: 30px; width:100%; overflow: hidden; height:240px;
                  img { width:100%; height:100%; object-fit: cover; transform: scale(1); transition: .3s transform ease-out;}
              }
              .cont_wrap {
                  .title {
                      font-size:24px; font-weight: 900; color:#000; margin-top:20px;
                      @include ellipsis-line(3);
                  }
                  .txt {
                      font-size:18px; font-weight: 400; color:#000; margin-top:18px;
                      @include ellipsis-line(3);
                  }
              }
          }
          .cs_tag { display:flex; justify-content: flex-start; flex-wrap: wrap; margin-top:34px;
              li { margin-right:15px; margin-bottom:7px;
                  a {border: 1px solid #C3CBCDE5; display:inline-block; padding:6px 10px; color: #3C64B1; font-size:16px;}
              }
          }
          .footer_wrap { position:relative; margin-top:20px; padding-right:100px; position: absolute; bottom:40px; width:calc(100% - 30px);
              .category { color:#004098; font-size:16px; }
              .date{ position:absolute; bottom:0; right:0; font-size:16px; color:#c3cbcd;}
          }
      }
  }
  .list_footer {margin-top:110px; font-size:0; text-align: center;

  }
}

.view_container { margin-top:85px;
  .view_header {padding-bottom:24px; border-bottom:1px solid #c3cbcd; position:relative;
      .category {font-size:24px; font-weight: 900; color:#004098; margin-bottom:10px;}
      .title {font-size:50px; font-weight: 900; color:#000;}
      .date {font-size:18px; color:#c3cbcd; font-weight: 400; position:absolute; bottom:-40px; right:0;}
  }
  .view_body { padding:72px 0; border-bottom:1px solid #c3cbcd;
    .view_inner {line-height: 1.5; min-height: 400px;
      img {max-width: 100%;}
    }
    .tag_box { margin-top:80px;
        .tag_item {color:#004098; font-size:18px; display:inline-block; margin-right:20px; font-weight:400;border:1px solid #C3CBCDE5; padding:6px 9px;}
    }
  }
  .view_footer { margin-top:40px;
      .navigator { display:flex; flex-wrap: wrap; justify-content: space-between; height:34px; position: relative;
          a, button {font-size:0;
              span {line-height: 32px; display:inline-block; vertical-align: top; font-size:16px;}
          }
          .btn_page_prev {display:inline-block; margin-right:16px;
            span { vertical-align: top; display: inline-block; line-height: 34px; }
          }
          .btn_page_next {display:inline-block; margin-left:16px;
            span { vertical-align: top; display: inline-block; line-height: 34px; }
          }

          .btn_view_prev {
            position: absolute; left:0;
          }
          .btn_view_next {
            position: absolute; right:0;
          }
      }

      .view_btn_wrap {
          margin-top:30px; text-align: center;
      }
  }
}
.write_mode {
  .view_container { 
    .view_header {border-bottom:none;
        .category {font-size:18px; font-weight: 400; color:#000; max-width: 400px; position:relative;padding-left:150px;
          >span {display:inline-block; position:absolute; top:0; left:0; line-height: 38px; width:20%;}
          input {height:24px; }
        }
        .title {font-size:18px; font-weight: 400; padding-left:150px; position:relative; margin-top:10px;
          span {display:inline-block; position:absolute; top:0; left:0; line-height: 38px;}
          input {height:38px;  border: 1px solid #c3cbcd;}
        }
        .setting { margin-top:15px;
          .sel_box + .sel_box { margin-left:30px;}
        }
    }
    .view_body {
      padding:0;border-bottom:none;
      .view_inner {margin-bottom:30px;}
      .tag_insert {font-size:18px; font-weight: 400; padding-left:150px; position:relative; margin-top:10px;
        span {display:inline-block; position:absolute; top:0; left:0; line-height: 38px;}
        input {height:38px;  border: 1px solid #c3cbcd; width:100%; margin-right:20px;}

      }
      .rep_image {font-size:18px; font-weight: 400; padding-left:150px; position:relative; margin-top:10px;
        span {display:inline-block; position:absolute; top:0; left:0; line-height: 38px;}
        input {height:38px;  margin-right:20px;}
        label.inp_file { cursor: pointer; line-height: 18px; border-radius:5px; overflow: hidden; background:#ff6600; color:#fff; font-size:15px; display:inline-block; padding: 10px 20px; vertical-align: top;
          & + input { display:none;}
        }
        .img_wrap { display:inline-block; margin-left:30px;
          img { height:200px; max-width: 800px;}
        }
        em {display:inline-block; line-height:38px; padding-left:20px; vertical-align: top; }
      }
    }
  }
}

.btn_page_prev { margin-right:20px;
  width:32px; height:32px; border:1px solid #C3CBCD; background:none; position:relative; display:inline-block;
  &:disabled {opacity: 0.5; cursor: default;}
  &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; }
}
.btn_page_next { margin-left:20px;
  &:disabled {opacity: 0.5; cursor: default;}
  width:32px; height:32px; border:1px solid #C3CBCD; background:none; position:relative; display:inline-block;
  &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; transform: scaleX(-1);}
}
.btn_page_num {font-size:18px; color:#828282; line-height: 32px; display:inline-block; margin: 0 5px; padding:0 5px;
  &.on {text-decoration: underline;}
}

















@media all and (max-width:$G-wMobile)  {

  .list_container {
    &.board_list {
      .list_header{margin-top:70px;  min-height: 80px; }
      .list_body { padding-top:0;
        .cs_list {  display:flex; flex-wrap: wrap;  margin-left:0px; justify-content: flex-start;
          > li {width:100%; padding-left:0px; padding-bottom:0;    border-bottom: 1px solid #C3CBCD; margin-bottom: 0;
          .cs_item {
              .cont_wrap {
                  .title {
                      font-size:15px; font-weight: 500; color:#000; margin-top:20px; margin-bottom:20px; padding-left:80px; padding-right:10px;
                      display:block; text-overflow: initial; overflow: visible;
                  }
                  .category {position: absolute; left:10px; color:#555; line-height: 20px; font-size:14px;}
                  .txt {
                      display:none;
                  }
              }
          }
        }
      }
    }
  }
  
    .list_header{ margin-top:40px; padding-bottom:16px; border-bottom:1px solid #C3CBCD; position:relative; min-height: 60px;
        .list_tag_wrap {padding-top:80px;
            .cs_tag { display:flex; flex-wrap: nowrap; justify-content: flex-start; overflow-x:auto; margin-right:-32px;
                .tag_item { margin-right:12px; padding:10px; white-space: nowrap;
                  &:last-child {margin-right:32px;}
                    button {
                        border:none; color:#004098; font-weight: 400; font-size:14px;
                    }
                    &.on button {font-weight: 700; text-decoration: underline;}
                }
            }
        }
        .list_search { background: #F6F6F6; border-radius: 40px; height:40px; overflow: hidden; width:100%; 
            position:absolute; top:0; right:0;
            input { background:none; font-size:14px; height:40px; padding: 0 50px 0 20px; border:none;}
            .btn_search {
                display:block; width:40px; height:40px; border:none; background:none; position:absolute; top:0; right:10px; 
                &:after {content:''; position:absolute; width:18px; height:18px; top:50%; left:50%; margin-top:-9px; margin-left:-9px; background:url('../../images/common/icon_search.png'); background-size:100%; background-repeat: no-repeat; }
            }
        }
    }
    .list_body { padding-top:40px;border-bottom:1px solid #C3CBCD; 
        .cs_list { display:flex; flex-wrap: wrap;  margin-left:0; justify-content: flex-start;
            > li {width:100%; padding-left:0px; padding-bottom:0px; position: relative; margin-bottom:50px;
              
            }
            .cs_item {
                .img_wrap { border-radius: 30px; width:100%; overflow: hidden; height:240px;
                    img { width:100%; height:100%; object-fit: cover; transform: scale(1); transition: .3s transform ease-out;}
                }
                .cont_wrap {
                    .title {
                        font-size:18px; font-weight: 900; color:#000; margin-top:20px;
                        @include ellipsis-line(1);
                    }
                    .txt {
                        font-size:14px; font-weight: 400; color:#000; margin-top:18px;
                        @include ellipsis-line(3);
                    }
                }
            }
            .cs_tag { display:flex; justify-content: flex-start; flex-wrap: wrap; margin-top:15px;
                li { margin-right:15px; margin-bottom:7px;
                  &:last-child {margin-right:0;}
                    a {border: 1px solid #C3CBCDE5; display:inline-block; padding:6px 10px; color: #3C64B1; font-size:14px;}
                }
            }
            .footer_wrap { position:relative; margin-top:10px; padding-right:100px;  bottom:auto; width:auto; 
                .category { color:#004098; font-size:14px; font-weight: 700; }
                .date{ position:absolute; bottom:0; right:0; font-size:14px;font-weight: 400; color:#c3cbcd;}
            }
        }
    }
    .list_footer {margin-top:25px; font-size:0; text-align: center;margin-bottom:110px;

    }
  }

  .view_container { margin-top:30px; padding:0 16px;
    .view_header {padding-bottom:10px; border-bottom:1px solid #c3cbcd; position:relative;
        .category {font-size:24px; font-weight: 900; color:#004098; margin-bottom:10px; display: none;}
        .title {font-size:24px; font-weight: 900; color:#000;}
        .date {font-size:14px; color:#c3cbcd; font-weight: 400; position:absolute; bottom:-30px; right:0;}
    }
    .view_body { padding:72px 0 42px; border-bottom:1px solid #c3cbcd;
      .view_inner {line-height: 1.5; min-height: 400px;
        img {max-width: 100%; width:100%; height:auto;}
        iframe { max-width: 340px; width:100%; height:200px;}
      }
      .tag_box { margin-top:80px; display:flex; flex-wrap:nowrap; overflow-x: auto; margin-right:-16px;
          .tag_item { white-space:nowrap; color:#004098; font-size:14px; display:inline-block; margin-right:16px; margin-bottom:10px; font-weight:400;border:1px solid #C3CBCDE5; padding:6px 9px;
            //&:last-child {margin-right:0;}
          }
      }
    }
    .view_footer { margin-top:40px;
        .navigator { display:flex; flex-wrap: wrap; justify-content: space-between; height:34px; position: relative;
            a, button {font-size:0;
                span {line-height: 32px; display:inline-block; vertical-align: top; font-size:14px;}
            }
            .btn_page_prev {display:inline-block; margin-right:10px;
              span { vertical-align: top; display: inline-block; line-height: 34px; }
            }
            .btn_page_next {display:inline-block; margin-left:10px;
              span { vertical-align: top; display: inline-block; line-height: 34px; }
            }

            .btn_view_prev {
              position: absolute; left:0;
            }
            .btn_view_next {
              position: absolute; right:0;
            }
        }

        .view_btn_wrap {
            margin-top:-38px; text-align: center; position: relative; margin-bottom:82px;
        }
    }
  }

  .btn_page_prev { margin-right:20px;
    width:32px; height:32px; border:1px solid #C3CBCD; background:none; position:relative; display:inline-block;
    &:disabled {opacity: 0.5; cursor: default;}
    &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; }
  }
  .btn_page_next { margin-left:20px;
    &:disabled {opacity: 0.5; cursor: default;}
    width:32px; height:32px; border:1px solid #C3CBCD; background:none; position:relative; display:inline-block;
    &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; transform: scaleX(-1);}
  }
  .btn_page_num {font-size:18px; color:#828282; line-height: 32px; display:inline-block; margin: 0 5px; padding:0 5px;
    &.on {text-decoration: underline;}
  }
}
