.solution { color:#000;
    .page_title-section .section-inner {
        background-image:url('../../images/visual/vs_solution.jpg'); background-repeat: no-repeat; background-position: center; 
    }
    
    .solution_intro {
        padding-top:170px; padding-bottom:170px;  margin-bottom:170px;
        text-align: center;background:#f0f2f5;
        .panel-body {margin-top:0px}
        .point_circle:before {
            content: "";z-index: 0;position: absolute;width: 312px;height: 312px;border-radius: 50%;background: #fff;overflow: hidden;left: 50%;top: 50%; transform: translate(-50%, -50%);
        }
        .tit {font-size:50px;font-weight: 900; word-break: keep-all;
            span {position:relative;}
        }
        .txt {margin:0 auto; margin-top:40px; max-width: 870px;  position: relative;
            font-size: 18px;font-weight: 400; word-break: keep-all; line-height: 1.5;
        }
        
    }

    .solution_header {color:#000; margin-top:100px;
        .head_title {font-size:50px;font-weight: 900; text-align: center; }
        .head_list {  display:flex; flex-wrap: wrap; justify-content: space-between; margin: 100px auto 300px; max-width: 1000px; position:relative;
            li { position:relative; text-align: center; padding: 30px; padding-top:210px; 
                &:before {content:''; position:absolute; top:0; left:50%; margin-left:-120px; width:240px; height:180px; background:#ccc; display:block;}
                .tit {font-size:24px; font-weight: 900;}
                .txt {font-size:18px; font-weight: 400; margin-top:15px;}
                &.item0:before {
                    background:url('../../images/sub/sol_info0.png'); background-size: 100%; background-repeat: no-repeat;
                }
                &.item1:before {
                    background:url('../../images/sub/sol_info1.png'); background-size: 100%; background-repeat: no-repeat;
                }
                &.item2:before {
                    background:url('../../images/sub/sol_info2.png'); background-size: 100%; background-repeat: no-repeat;
                }
            }
            &:before {content:''; width:1px; height:170px; background:#e0e0e0; position:absolute; left:30%; top:20px;}
            
            &:after {content:''; width:1px; height:170px; background:#e0e0e0; position:absolute; left:70%; top:20px;}
        }
    }
    .page-solution { color:#000; letter-spacing: -0.7px;
        & +.page-solution {margin-top:270px;}
        .section-inner {display:flex; justify-content: flex-start;}
        .panel-header { width:39%;
            .tit {font-size:50px;font-weight: 900; word-break: keep-all;}
            .txt { margin-top:50px;
                font-size: 18px;font-weight: 400; word-break: keep-all; line-height: 1.5;
            }
        }
        .panel-body { width:59%; padding-left:2%;
            .scroll_imgs {
                .img_item { margin-top:96px; position:relative;
                    &:first-child{margin-top:180px;}
                    &.pos1 {text-align: center;}
                    &.pos2 {text-align: right;}
                    .item_inner {width:73%; display:inline-block;}
                    .img_wrap { 
                        img{ width:100%;}
                    }
                    .tit {margin-top:20px; font-size:24px; font-weight: 900; text-align: right;}
                }
            }
        }
        .btn_wrap { margin-top:20px;
            a { display:inline-block; padding-right:20px; position:relative; color:#004098; font-weight: 700; font-size:18px; margin:8px 0;
                .ic_arrow_link {display:inline-block; width:12px; height:8px; position:absolute; right:0; top:50%; transform: translateY(-50%); background:url('../../images/common/solution_arrow.png'); background-repeat: no-repeat; background-size:100%;}
            }
            
        }
    }
}

.btn_arrow { display:inline-block; padding-right:20px; position:relative; color:#004098; font-weight: 700; font-size:18px; margin:8px 0;
    .ic_arrow_link {display:inline-block; width:12px; height:8px; position:absolute; right:0; top:50%; transform: translateY(-50%); background:url('../../images/common/solution_arrow.png'); background-repeat: no-repeat; background-size:100%;}
}



@media all and (max-width:$G-wMobile)  {
    .solution { 
        .solution_intro {
            padding-top:110px; padding-bottom:70px;  margin-bottom:0px;
            .panel-body {margin-top:0px}
            .point_circle:before {
                content: "";z-index: 0;position: absolute;width: 170px;height: 170px;border-radius: 50%;background: #fff;overflow: hidden;left: 50%;top: 50%; transform: translate(-50%, -50%);
            }
            .tit {font-size:24px;font-weight: 900; word-break: keep-all;
                span {position:relative;}
            }
            .txt {margin:0 auto; margin-top:40px; max-width: 870px;  position: relative;
                font-size: 14px;font-weight: 400; word-break: keep-all; line-height: 1.5;
            }
            
        }
    
        .solution_header {color:#000; margin-top:100px;
            .head_title {font-size:24px;font-weight: 900; text-align: center; }
            .head_list {  display:flex; flex-wrap: wrap; justify-content: space-between; margin: 100px auto 300px; max-width: 1000px; position:relative;
                li { position:relative; text-align: center; padding: 30px; padding-top:210px; 
                    &:before {content:''; position:absolute; top:0; left:50%; margin-left:-120px; width:240px; height:180px; background:#ccc; display:block;}
                    .tit {font-size:24px; font-weight: 900;}
                    .txt {font-size:18px; font-weight: 400; margin-top:15px;}
                    &.item0:before {
                        background:url('../../images/sub/sol_info0.png'); background-size: 100%; background-repeat: no-repeat;
                    }
                    &.item1:before {
                        background:url('../../images/sub/sol_info1.png'); background-size: 100%; background-repeat: no-repeat;
                    }
                    &.item2:before {
                        background:url('../../images/sub/sol_info2.png'); background-size: 100%; background-repeat: no-repeat;
                    }
                }
                &:before {content:''; width:1px; height:170px; background:#e0e0e0; position:absolute; left:30%; top:20px;}
                
                &:after {content:''; width:1px; height:170px; background:#e0e0e0; position:absolute; left:70%; top:20px;}
            }
        }
        .page-solution { color:#000; letter-spacing: -0.7px; padding-top:100px; margin-bottom:80px;
            & +.page-solution {margin-top:0px;}
            &:last-child {margin-bottom:100px;}
            .section-inner {display:block;}
            .panel-header { width:100%;
                .tit {font-size:24px;font-weight: 900; word-break: keep-all;}
                .txt { margin-top:50px;
                    font-size: 14px;font-weight: 400; word-break: keep-all; line-height: 1.5;
                }
            }
            .panel-body { width:100%; padding-left:0;
                .scroll_imgs {
                    .img_item { margin-top:30px; position:relative;text-align: center;
                        &:first-child{margin-top:30px;}
                        &.pos1 {text-align: center;}
                        &.pos2 {text-align: center;}
                        .item_inner {width:100%; display:inline-block;}
                        .img_wrap { 
                            img{ width:100%; max-width: 500px;}
                        }
                        .tit {margin-top:4px; font-size:16px; font-weight: 700; text-align: center;}
                    }
                }
            }
            .btn_wrap { margin-top:20px;
                a { display:inline-block; padding-right:20px; position:relative; color:#004098; font-weight: 700; font-size:14px; margin:8px 0;
                    .ic_arrow_link {display:inline-block; width:12px; height:8px; position:absolute; right:0; top:50%; transform: translateY(-50%); background:url('../../images/common/solution_arrow.png'); background-repeat: no-repeat; background-size:100%;}
                }
                
            }
        }
    }
    
    .btn_arrow { display:inline-block; padding-right:20px; position:relative; color:#004098; font-weight: 700; font-size:14px; margin:8px 0;
        .ic_arrow_link {display:inline-block; width:12px; height:8px; position:absolute; right:0; top:50%; transform: translateY(-50%); background:url('../../images/common/solution_arrow.png'); background-repeat: no-repeat; background-size:100%;}
    }
    
}