.main_pc {
  .header_wrap {
    height: auto;
  }
  .section {
    overflow: hidden;
    position: relative;
  }
  .section_inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
  }
  .s_title {
    letter-spacing: -1.6px;
    position: relative;
    font-size: px(50);
    font-weight: 900;
    line-height: 1.48;
    overflow: hidden;
  }
  .s_desc {
    letter-spacing: -1px;
    position: relative;
    line-height: 1.58;
    word-break: keep-all;
    font-size: px(18);
  }
  .mwc_btnWrap {
    margin:30px 0;
  }
  .frame_wrap {
    font-size: 0;
    max-width: px(1060);
    position: relative;
    margin: 40px auto;
    //transition: .05s transform;
    .frame_inner {
      border-radius: px(20);
      //overflow:hidden;
      transform: perspective(900px) rotateX(-4deg) rotateY(-14.5934deg);
      box-shadow: rgba(0, 0, 0, 0.3) px(5) px(5) px(20);
      outline: 1px solid transparent;
      backface-visibility: hidden;
      touch-action: pinch-zoom;
      will-change: transform;
      //border-radius: 10px; overflow:hidden; border:20px solid #000;
      box-sizing: border-box;
      //&:after {content:''; position:absolute; left:50%; bottom:px( -30);transform: translateX(-50%); width:px(40); height:px(13); background:url('../../images/common/icon_rotate.png'); background-size:100%; background-repeat: no-repeat;}
    }
    img,
    canvas,
    video {
      width: 100%;
    }
    .icon_wrap {
      position: absolute;
      left: 50%;
      bottom: px(-70);
      transform: translateX(-50%);
      width: px(125);
      height: px(51);

      //background:url('../../images/main/icon_frm_hand.svg'); background-size:100%; background-repeat: no-repeat;
      // &:before {content:''; position:absolute; left:-40px; top:3px; width:33px; height:22px;  background:url('../../images/main/icon_frm_arrow.svg'); background-size:100%; background-repeat: no-repeat;}
      // &:after {content:''; position:absolute; right:-34px; top:3px; width:33px; height:22px;  background:url('../../images/main/icon_frm_arrow.svg'); background-size:100%; background-repeat: no-repeat; transform: rotate(180deg);}
    }
  }

  .slt_wrap {
    margin-top: 150px;
    .slt_cont {
      .swiper-wrapper {
        .swiper-slide {
          position: relative;
          .slt_cont_img {
            width: 57.5%;
            border-radius: 20px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .slt_cont_txt {
            width: 40.3%;
            padding: 35px;
            right: 0;
            position: absolute;
            border-radius: 20px;
            background: #f0f2f5;
            min-height: 80%;
            top: 0;
            .tit {
              font-size: 24px;
              color: #004098;
              font-weight: 700;
            }
            .txt {
              font-size: 18px;
              color: #000;
              font-weight: 400;
              margin-top: 26px;
              line-height: 1.5;
              word-break: keep-all;
            }
          }
        }
      }
    }
  }

  .video_wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    video,
    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  #section0 {
    height: 100vh;
    .section_wrap {
      position: relative;
      z-index: 2;
    }
    .s_title {
      color: #fff;
      margin-top: px(230);
      text-shadow: 0px 0px 12px rgba(26, 255, 255, 0.6), 0px 0px 12px #000000;
    }
    .s_desc {
      margin-top: px(67);
      color: #fff;
    }
  }
  #section1 {
    background: #000; //#2e2e2e;
    .section_inner {
      position: relative;
    }
    .section_wrap {
      position: relative;
      padding: 150px 0;
    }
    .s_title {
      color: #fff;
      text-align: center;
      max-width: 1200px;
      margin: 0 auto;
    }
    .s_desc {
      text-align: center;
      margin-top: px(36);
      color: #fff;
    }
  }
  .switch_img { position: relative;padding: 40px 0;
    .switch_img0 {width: 60%;}
    .switch_img1 {
      width: 35%;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }
  #section1-1 {
    .section_inner {
      position: relative;
    }
    .section_wrap {
      position: relative;
      padding: 150px 0;
    }
    .s_title {
      color: #000;
      text-align: center;
      max-width: 1200px;
      margin: 0 auto;
    }
    .s_desc {
      text-align: center;
    }
    .img_wrap {
      text-align: center;
      margin: 100px 0;
      img {
        width: 80%;
        max-width: 600px;
      }
    }
  }
  #section2 {
    .section_inner {
      /*padding:0 18px;*/
      position: relative;
    }
    .section_wrap {
      padding: 150px 0;
    }

    .s_title {
      color: #000;
      text-align: center;
    }
    .s_desc {
      text-align: center;
      margin-top: px(40);
      color: #000;
    }

    .card_wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 100px;
      .card_cont {
        width: 32%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        .img_wrap {
          position: relative;
          img {
            width: 100%;
          }
          &:after {
            content: "";
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 60.94%,
              rgba(0, 0, 0, 0.7) 100%
            );
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
        }
        .info_cont {
          color: #fff;
          position: absolute;
          bottom: 53px;
          left: 0;
          right: 0;
          padding: 0px 38px;
          transition: 0.3s all;
          height: 32px;
          overflow: hidden;
          .name {
            font-size: 24px;
            font-weight: 900;
          }
          .description {
            font-size: 18px;
            font-weight: 400;
            margin-top: 12px;
            line-height: 1.6;
            opacity: 0;
            transition: 0.3s all;
          }
        }
        &:hover {
          .info_cont {
            height: 140px;
            bottom: 20px;
            .name {
            }
            .description {
              opacity: 1;
            }
          }
        }
      }
    }

    .btn_wrap {
      margin-top: px(50);
    }
    .solution_list {
      margin-top: px(40);
      perspective: 1800px;
      padding: 0 px(60);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: px(427);
        position: relative;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        &:hover {
          .img_wrap img {
            transform: scale(1.06);
          }
        }
        .img_wrap {
          border-radius: px(30);
          overflow: hidden;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0%;
            right: 0%;
            top: 0%;
            bottom: 0%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 35.42%,
              rgba(0, 0, 0, 0.8) 100%
            );
          }
          img {
            width: 100%;
            transition: 0.3s all ease-out;
            transform: scale(1);
          }
        }
        .tit {
          color: #fff;
          font-size: px(24);
          font-weight: 900;
          position: absolute;
          bottom: px(42);
          left: px(35);
        }
      }
    }
  }
  #section3 {
    background: #f0f2f5;
    .section_inner {
      position: relative;
    }
    .section_wrap {
      padding: 150px 0;
    }
    .s_title {
      color: #000;
      text-align: center;
    }
    .s_desc {
      margin-top: px(34);
      color: #000;
      text-align: center;
    }

    // .sect_bg { position:absolute; top:0; left:0; width:100%; height:100%; background:url('../../images/temp/Morgan-Stanley-declares-Metaverse-the-next-big-investment-trend-of.jpg'); background-size:cover;
    //   &:after {content:''; position:absolute; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.3)}
    // }
  }
  #section7 {
    background: #f0f2f5;
    .section_inner {
      position: relative;
    }
    .section_wrap {
      padding: 150px 0;
    }
    .s_title {
      color: #000;
      text-align: center;
    }
    .s_desc {
      margin-top: px(34);
      color: #000;
      text-align: center;
    }

    .b2b_wrap {
      margin-top: px(55);
      background: #fff;
      border-radius: 20px;
      padding: px(50);
      .b2b_inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .bs_box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 32%;

            img {
              width: 100%;
            }
          }
        }
      }
      & + .btn_wrap {
        margin-top: px(50);
      }
    }
  }
  #section4 {
    background: #fff;
    .section_inner {
      /*padding:0 18px;*/
      position: relative;
    }
    .section_wrap {
      padding: 150px 0;
    }
    .s_title {
      color: #000;
      text-align: center;
      margin-bottom: 58px;
    }
    .customer_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: px(1045);
      margin: 0 auto;
      position: relative;
      li {
        width: 32%;
        margin-bottom: px(20);
        img {
          width: 100%;
        }
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: px(143);
        left: 0;
        bottom: px(-30);

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 42.19%
        );
      }
    }
    .btn_wrap {
      margin-top: 50px;
    }
  }
  #section5 {
    background: #fff;

    .swiper-button-prev {
      width: 32px;
      height: 32px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: px(-250);
      margin-left: 0px;
      border: 1px solid #c3cbcd;
      background: #fff;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        margin-left: -4px;
        background: url("../../images/common/icon_page_arrow.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
    .swiper-button-next {
      width: 32px;
      height: 32px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: px(-250);
      margin-left: 32px;
      border: 1px solid #c3cbcd;
      background: #fff;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        margin-left: -4px;
        background: url("../../images/common/icon_page_arrow.png");
        background-size: 100%;
        background-repeat: no-repeat;
        transform: scaleX(-1);
      }
    }
  }
  #section2 {
    background: #fff;

    .swiper-button-prev {
      width: 32px;
      height: 32px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 59.7%;
      margin-left: 0px;
      border: 1px solid #c3cbcd;
      background: #fff;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        margin-left: -4px;
        background: url("../../images/common/icon_page_arrow.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
    .swiper-button-next {
      width: 32px;
      height: 32px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 59.7%;
      margin-left: 32px;
      border: 1px solid #c3cbcd;
      background: #fff;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        margin-left: -4px;
        background: url("../../images/common/icon_page_arrow.png");
        background-size: 100%;
        background-repeat: no-repeat;
        transform: scaleX(-1);
      }
    }
  }
  #section6 {
    &.active {
      .sect_bg {
        transform: scale(1);
      }
    }
    .sect_bg {
      transform: scale(1.2);
      transition: 1.6s transform;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../images/main/bg_contact.jpg");
      background-size: cover;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    .section_inner {
      /*padding:0 18px;*/
      position: relative;
      height: 100vh;
      max-height: 700px;
    }
    .section_wrap {
      padding-top: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      left: 0;
    }

    .btn_wrap {
      margin-top: px(40);
    }
    .s_title {
      color: #fff;
      text-align: center;
    }
  }

  .news_section {
    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 150px);
      height: 100%;
      width: 100%;
      background: #f6f6f6;
    }
    .section_inner {
      padding: 0 40px;
      position: relative;
    }
    .section_wrap {
      padding: 150px 0 450px;
      padding-left: px(250);
    }

    .news_header {
      position: absolute;
      left: 40px;
      top: 50%;
      margin-top: px(-307);
      z-index: 2;
      .s_title {
        color: #000;
      }
      .btn_wrap {
        margin-top: px(27);
      }
    }
    .news_body {
      .swiper {
        overflow: visible;
      }

      .swiper {
        margin-top: px(80);
        position: relative; //margin-right:-100%;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: translateX(-100%) translateY(-10%);
          top: 0;
          height: 120%;
          left: px(-20);
          background: linear-gradient(180deg, #fff 44.7%, #f6f6f6 44.7%);
          z-index: 1;
        }
        .swiper-wrapper {
          .swiper-slide {
            width: px(334);
            height: px(480);
            border-radius: px(30);
            overflow: hidden;
            position: relative;
            background-color: #fff;
            box-shadow: 2px 4px 16px rgba(195, 203, 205, 0.2);
            transition: 0.2s box-shadow;
            &:hover {
              box-shadow: 6px 6px 30px rgba(195, 203, 205, 0.9);
              .img_wrap {
                .img_inner {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }
            .link {
              display: block;
              position: relative;
              height: 100%;
              &:after {
                content: "";
                width: px(33);
                height: px(14);
                display: block;
                background: url("../../images/common/news_arrow.png");
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                bottom: px(33);
                right: px(20);
              }
            }
            .img_wrap {
              .img_inner {
                position: relative;
                overflow: hidden;
                width: px(334);
                height: px(200);
                img {
                  transition: 0.2s transform ease-out;
                  //position: absolute; width: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%);
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .cont_wrap {
              padding: px(22) px(35);
              .cont_inner {
                .tit {
                  font-size: px(28);
                  font-weight: 500;
                  line-height: 1.5;
                  color: #000;
                  @include ellipsis-line(3);
                }
                .date {
                  position: absolute;
                  bottom: px(30);
                  left: px(35);
                  color: #c3cbcd;
                  font-size: px(18);
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }

  .business_wrap {
    margin-top: px(55);
    .business_inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .bs_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 32%;
          position: relative;
          background: #fff;
          border-radius: 20px;
          margin-top: 95px;
          .circle_wrap {
            text-align: center;
            border-radius: 50%;
            width: 46%;
            padding-bottom: 46%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            background: #f00;
          }
          padding: 156px 25px 60px 25px;
          text-align: center;
          .cc_tit {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 38px;
            font-weight: 900;
            color: #fff;
          }
          .tit {
            font-weight: 700;
            margin-bottom: 40px;
            font-size: 18px;
          }
          .txt {
            font-size: 18px;
            word-break: keep-all;
            line-height: 28px;
          }
        }
        li.bs_inner0 .circle_wrap {
          background: #004098;
        }
        li.bs_inner1 .circle_wrap {
          background: #558bcb;
        }
        li.bs_inner2 .circle_wrap {
          background: #7580bd;
        }
      }

      .bs_list {
        border-radius: px(30);
        overflow: hidden;
        width: px(538);
        .bs_item {
          border-top: 1px solid #bdbdbd;
          &:first-child {
            border-top: none;
          }
          button {
            transition: 0.2s background;
            width: 100%;
            text-align: left;
            &.on {
              background: #f2f2f2;
              span {
                font-weight: 700;
                color: #004098;
              }
            }
            display: flex;
            height: px(130);
            justify-content: flex-start;
            align-items: center;
            padding: px(20) px(40);
            text-align: left;
            span {
              font-size: px(18);
              font-weight: 700;
              color: #000;
              line-height: 1.4;
              transition: 0.2s color;
            }
          }
        }
      }
      .bs_slide_wrap {
        width: 58%;
        border-radius: px(30);
        overflow: hidden;
        .swiper {
          border-radius: px(30);
          .swiper-slide {
            text-align: center;
            .img_wrap {
              img {
                width: 100%;
              }
            }
            .title {
              font-size: px(24);
              color: #000;
              font-weight: 900;
            }
            .txt {
              font-size: px(18);
              font-weight: 400;
              margin-top: px(15);
              color: #fff;
              position: absolute;
              bottom: px(20);
              left: 0;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .consulting_wrap {
    display: flex;
    flex-wrap: wrap;
    width: px(1163);
    margin-top: px(90);
    margin: px(100) auto 0;
    .consulting_box {
      position: relative;
      &.box_l {
        width: px(453);
        margin-right: px(20);
      }
      &.box_r {
        width: px(690);
      }
      &.box_b {
        width: 100%;
        margin-top: px(18);
        text-align: right;
      }
      .consult_cont {
        margin-top: px(20);
        &:first-child {
          margin-top: 0;
        }
        label.label_text,
        .label {
          font-size: px(18);
          color: #000;
          font-weight: 700;
          display: block;
          padding: px(14) px(15);
        }
        input[type="text"] {
          background: #fff;
          border: 0;
          color: #000;
          width: 100%;
          font-size: px(18);
        }
        input[type="radio"] {
          background: #fff;
          border: 0;
          color: #000;
          font-size: px(18);
        }
        input[type="radio"] + label {
          font-size: px(18);
          color: #000;
          font-weight: 500;
        }
        .must {
          color: #f00;
          display: inline-block;
          padding-left: px(4);
        }
        textarea {
          height: px(559);
          padding: px(11) px(17);
          font-weight: 500;
          height: calc(100% - 3.91944vw);
          position: absolute;
        }
        .sel_wrap {
          background: #fff;
          padding: px(15) px(17) px(16);
        }
        .trp.radio-box {
          padding-left: px(30);
          min-height: px(24);
          & + .trp.radio-box {
            margin-left: px(40);
          }
          label {
            font-size: px(18);
          }
          input[type="radio"] + i {
            top: 50%;
            margin-top: px(-10);
            top: 50%;
            &:after {
              width: px(20);
              height: px(20);
            }
          }
        }
      }
      .msg {
        display: inline-block;
        font-size: px(18);
        font-weight: 700;
        color: #000;
        padding-right: px(10);
      }
    }
  }

  .footer {
    position: relative;
    margin-top: 77px;
  }
}

:root {
  --card-width: 397px;
  --card-height: 558px;

  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}

.carousel {
  position: relative;
  width: 100%;
  height: var(--card-height);
  margin-top: px(50);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: auto;

  &__btn {
    --btn-size: 35px;
    width: var(--btn-size);
    height: var(--btn-size);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    border: 1px solid #fff;
    transition: 0.2s background;
    background: rgba(255, 255, 255, 0);
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 16px;
      margin-top: -8px;
      margin-left: -4px;
      background: url("../../images/common/icon_page_arrow.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    .btn_page_next {
      margin-left: 20px;
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
      width: 32px;
      height: 32px;
      border: 1px solid #c3cbcd;
      background: none;
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        margin-left: -4px;
        background: url("../../images/common/icon_page_arrow.png");
        background-size: 100%;
        background-repeat: no-repeat;
        transform: scaleX(-1);
      }
    }

    &.btn--left {
      left: 50%;
      margin-left: px(-300);
    }
    &.btn--right {
      right: 50%;
      margin-right: px(-300);
      &:after {
        transform: scaleX(-1);
      }
    }

    .icon {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .cards__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
}

.card {
  --card-translateY-offset: 0; //100vh;

  border-radius: px(30);
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--card-translateX-offset))
    translateY(var(--card-translateY-offset))
    rotateY(var(--card-rotation-offset)) scale(var(--card-scale-offset));
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  transition: transform var(--card-transition-duration)
    var(--card-transition-easing);
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
    transition: opacity var(--card-transition-duration)
      var(--card-transition-easing);
    opacity: calc(1 - var(--opacity));
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.current--card {
    --current-card-rotation-offset: 0;
    --card-translateX-offset: 0;
    --card-rotation-offset: var(--current-card-rotation-offset);
    --card-scale-offset: 1;
    --opacity: 0.8;
    //cursor:pointer;
  }

  &.previous--card {
    --card-translateX-offset: calc(-1 * var(--card-width) * 0.95);
    --card-rotation-offset: 52deg;
  }

  &.next--card {
    --card-translateX-offset: calc(var(--card-width) * 0.95);
    --card-rotation-offset: -52deg;
  }

  &.previous--card,
  &.next--card {
    --card-scale-offset: 0.9;
    --opacity: 0.4;
  }
}

.infoList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: var(--card-height);
  pointer-events: none;

  .info__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    perspective: 1500px;
    transform-style: preserve-3d;
  }
}

.info {
  margin-top: calc(var(--card-height) / 1.38);
  margin-left: calc(var(--card-width));
  transform: translateZ(2rem);
  transition: transform var(--card-transition-duration)
    var(--card-transition-easing);
  width: var(--card-width);
  padding: px(20);

  .text {
    position: relative;
    font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
    //white-space: nowrap;
    color: #fff;
    width: fit-content;
  }

  .name,
  .location {
    text-transform: uppercase;
  }

  .location {
    font-weight: 800;
  }

  .location {
    --mg-left: 40px;
    --text-size-offset: 0.12;
    font-weight: 600;
    margin-left: var(--mg-left);
    margin-bottom: calc(var(--mg-left) / 2);
    padding-bottom: 0.8rem;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #fff;
      left: 0%;
      transform: translate(calc(-1 * var(--mg-left)), -50%);
    }
    &::before {
      top: 50%;
      width: 20px;
      height: 5px;
    }
    &::after {
      bottom: 0;
      width: 60px;
      height: 2px;
    }
  }
  .name {
    font-size: px(24);
    font-weight: 900;
  }
  .description {
    font-size: px(18);
    font-weight: 400;
    margin-top: px(15);
    //--text-size-offset: 0.065;
    font-weight: 500;
  }

  &.current--info {
    opacity: 1;
    display: block;
  }
  &.previous--info,
  &.next--info {
    opacity: 0;
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  :root {
    --card-width: 397px;
    --card-height: 558px;
  }
}

.main_pc {
  .bs_acd {
    width: 42%;
    padding-left: px(30);
    .bs_acd_item {
      border-bottom: 1px solid #c3cbcd;
      .item_head {
        border-top: 1px solid #c3cbcd;
        position: relative;
        button {
          display: block;
          line-height: 1.4;
          font-weight: 700;
          font-size: px(18);
          padding: 15px 40px 15px 12px;
          width: 100%;
          text-align: left;
        }
        &:after {
          content: "";
          width: px(16);
          height: px(8);
          background: url("../../images/common/acord_arrow.png");
          background-size: 100%;
          display: block;
          position: absolute;
          top: 50%;
          right: px(12);
          margin-top: px(-4);
          transform: rotate(180deg);
          // transition: all .2s;
        }
      }
      .item_body {
        padding: px(12) px(12) px(38);
        display: none;
        span {
          font-size: px(18);
          font-weight: 400;
          color: #000;
          line-height: 1.55;
          min-height: px(157);
          display: inline-block;
        }
      }
      &.on {
        .item_head {
          border-top: 1px solid #c3cbcd;
          button {
            color: #004098;
          }
          &:after {
            transform: rotate(0deg);
          }
        }
        .item_body {
          // display:block;
        }
      }
    }
  }
  #section_holo {
    background: linear-gradient(180deg, #000000 60%, #0a0701 100%);
    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      //background: linear-gradient(180deg, #000000 0%, #0a0701 100%);
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
    .section_outer {
      min-height: 100vh;
    }
    .holo_title {
      font-size: 64px;
      font-weight: 900;
      color: #fff;
      text-align: center;
      padding-top: px(200);
      line-height: 1.5;
      .ef_glow {
        will-change: opacity;
        color: #fff; /* 텍스트 색상 */
        text-shadow: 0 0 8px rgba(20, 20, 255, 0.7),
          0 0 16px rgba(20, 20, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.5),
          0 0 40px rgba(0, 255, 255, 0.4), 0 0 50px rgba(0, 255, 255, 0.3);
      }
    }
    .img_wrap.main_catImg {
      text-align: center;
      margin-top: px(150);
      padding-bottom: 100px;
      img,
      video {
        width: 100%;
        max-width: 850px;
      }
    }
    .holo_txt {
      padding-right: 450px;
      max-width: 1200px;
      p {
        font-size: 24px;
        color: #86868b;
        margin-top: px(50);
        line-height: 1.5;
        .txt_white {
          color: #fff;
        }
        &.desc {
          font-size: 18px;
          margin-top: 30px;
        }
      }
      ul {
        margin-top: 50px;
        li {
          padding-left: 10px;
          position: relative;
          color: #86868b;
          font-size: 18px;
          line-height: 1.5;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background: #86868b;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 14px;
            margin-top: -2px;
          }
        }
      }
    }
    .holo_wrap1 {
      padding-bottom: 250px;
      position: relative;
      margin-top: 100px;
      .img_wrap {
        position: absolute;
        top: -30px;
        right: 0;
      }
    }
  }
}

.main_pc .en {
  .business_wrap .business_inner .bs_slide_wrap .bs_slide_inner li .txt {
    font-size: px(15);
  }
}

.video_wrap {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    z-index: 1;
  }
}
