.casestudy {
    .page_title-section .section-inner {
        background-image:url('../../images/visual/vs_casestudy.jpg'); background-repeat: no-repeat; background-position: center; 
    }
}


.cs_slide { margin-top:96px; height:360px;
    .swiper {
        .swiper-slide {height:360px; position:relative; padding-left: calc( 50% + 50px );
            .img_wrap { border-radius: 30px; position:absolute; width:calc( 50% - 10px ); left:0; top:0; overflow: hidden; height:360px;
                img { width:100%; height:100%; object-fit: cover;}
            }
            .cont_wrap {
                display: flex; flex-wrap: wrap; justify-content: center; align-items: center; height: 100%;
                &.vtop { align-items:flex-start ;}
                .cont_inner {
                    width:100%;
                }
            }
            .title {font-size:50px; font-weight: 900; color:#000;
                @include ellipsis-line(4); word-break: keep-all;
            }
            .txt {font-size:18px; font-weight: 400; color:#000; margin-top:22px;
                @include ellipsis-line(3);
            }

        }
        .swiper-button-prev {
            width:32px; height:32px; position:absolute; top:auto; bottom:0; left:50%; margin-left:50px; border:1px solid #C3CBCD;
            background:#fff; 
            &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; }
        }
        .swiper-button-next {
            width:32px; height:32px; position:absolute; top:auto; bottom:0; left:50%; margin-left:82px; border:1px solid #C3CBCD;
            background:#fff;
            &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; transform: scaleX(-1);}
        }
    }
}



@media all and (max-width:$G-wMobile)  {

    .cs_slide { margin-top:40px; height:auto;
        .swiper { overflow: visible;
            .swiper-slide {height:auto; position:relative; padding-left: 0;
                .img_wrap { border-radius: 30px; position:relative; width:auto; padding-bottom:56.134%; left:0; top:0; overflow: hidden; height:auto;
                    img { width:100%; height:100%; object-fit: cover; position:absolute;}
                }
                .cont_wrap {
                    display: block; height: auto;
                    &.vtop { align-items:flex-start ;}
                    .cont_inner {
                        width:100%;
                    }
                }
                .title {font-size:18px; font-weight: 700; color:#000; text-align: center; margin-top:15px;
                    @include ellipsis-line(2);
                }
                .txt {font-size:14px; font-weight: 400; color:#000; margin-top:15px; 
                    @include ellipsis-line(4);
                }

            }
            .swiper-button-prev {display:none;}
            .swiper-button-next {display:none;}
        }
    }

}