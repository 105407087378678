.contact {
    background:#f0f2f5; color:#000;
    position: relative;
    z-index: 0;
    .page_title-section .section-inner { background-color:#f0f2f5;
        background-image:url('../../images/visual/vs_company.jpg'); background-repeat: no-repeat; background-position: center; 
    }
    
    .tit {font-size:50px; font-weight: 900; word-break: keep-all;}
    .txt {font-size:18px; font-weight: 400; line-height: 1.5; word-break: keep-all;}

    .panel-header  { margin-top:100px; margin-bottom:100px;}
    .consulting_box { position:relative; display:flex; flex-wrap: wrap;  justify-content: space-between;
        .consult_cont { margin-top:20px; width:100%;
            &.col_half {
                width:48%;
            }
          &:first-child {margin-top:0;}
          label.label_text, .label {
            font-size:18px; color:#000; font-weight: 700; display:block; padding: 15px;
          }
          input[type="text"] {
            background: #fff; border:0; color:#000; width:100%; font-size:18px;
          }
          input[type="radio"] {
            background: #fff; border:0; color:#000; font-size:18px;
          }
          input[type="radio"] + label {
            font-size:18px; color:#000; font-weight: 500;
          }
          .must {
            color:#f00; display:inline-block; padding-left:4px;
          }
          textarea {height:559px; padding: 11px 17px; font-weight: 500;}
          .sel_wrap {
             padding:15px 17px 16px;
          }
          .trp.radio-box {padding-left:30px; min-height:24px;
            & + .trp.radio-box { margin-left:70px; }
            label { font-size:18px; cursor: pointer;}
            input[type="radio"] + i {
              top:50%; margin-top:-10px; top:50%;
              &:after {
                width:20px; height:20px;
              }
            }
          }
        }
        .btn_wrap { margin-top:30px; text-align: right; width:100%; margin-bottom:130px;}
        .msg { display:inline-block; font-size:18px; font-weight: 700; color:#000; padding-right:10px;}
      }

}



.privacy-contents { color:#000; margin-bottom:130px;
    h3 {font-size:36px; font-weight: 700; margin-bottom:30px;line-height: 1.5;}
    h4 {font-size:24px; font-weight: 700; margin-top:60px; margin-bottom:15px;}
    h5 { font-size:20px; font-weight: 700;margin-bottom:20px;}
    p {
        font-size:18px; font-weight: 400; line-height: 1.5; padding:10px 0 10px 0px; 
    }
    ol { margin-top:30px; 
        li {
            
        }
    }
    ul { margin-left:15px;
        li {margin-top:30px;}
        &+p {margin-top:50px;}
    }
    dl { position:relative; padding-left:100px;  margin-top:20px;
        dt { position:absolute; left:0; font-size:18px; font-weight: 400;}
        dd { margin-bottom:10px; font-size:18px; font-weight: 400;}
    }
}




@media all and (max-width:$G-wMobile)  {
  .contact {
    
    .tit {font-size:24px; font-weight: 700; word-break: keep-all;}
    .txt {font-size:16px; font-weight: 700; line-height: 1.5; word-break: keep-all;}

    .panel-header  { margin-top:84px; margin-bottom:40px;
      .txt {margin-top:10px;}
    }
    .consulting_box { position:relative; display:flex; flex-wrap: wrap;  justify-content: space-between;
        .consult_cont { margin-top:20px; width:100%;
            &.col_half {
                width:100%;
            }
          &:first-child {margin-top:0;}
          label.label_text, .label {
            font-size:14px; color:#000; font-weight: 700; display:block; padding: 15px 0;
          }
          input[type="text"] {
            background: #fff; border:0; color:#000; width:100%; font-size:14px;
          }
          input[type="radio"] {
            background: #fff; border:0; color:#000; font-size:14px;
          }
          input[type="radio"] + label {
            font-size:14px; color:#000; font-weight: 500;
          }
          .must {
            color:#f00; display:inline-block; padding-left:4px;
          }
          textarea {height:285px; padding: 11px 17px; font-weight: 500; font-size:14px;}
          .sel_wrap {
             padding:0px 17px 16px;
          }
          .trp.radio-box {padding-left:30px; min-height:24px; display: block;
            & + .trp.radio-box { margin-left:0px; margin-top:10px; }
            label { font-size:14px; cursor: pointer;}
            input[type="radio"] + i {
              top:50%; margin-top:-10px; top:50%;
              &:after {
                width:20px; height:20px;
              }
            }
          }
        }
        .btn_wrap { margin-top:30px; text-align: right; width:100%; margin-bottom:130px;}
        .msg { display:inline-block; font-size:14px; font-weight: 700; color:#000; padding-right:10px;}
      }

}



.privacy-contents { color:#000; margin-bottom:130px;
    h3 {font-size:36px; font-weight: 700; margin-bottom:30px;line-height: 1.5;}
    h4 {font-size:24px; font-weight: 700; margin-top:60px; margin-bottom:15px;}
    h5 { font-size:20px; font-weight: 700;margin-bottom:20px;}
    p {
        font-size:18px; font-weight: 400; line-height: 1.5; padding:10px 0 10px 0px; 
    }
    ol { margin-top:30px; 
        li {
            
        }
    }
    ul { margin-left:15px;
        li {margin-top:30px;}
        &+p {margin-top:50px;}
    }
    dl { position:relative; padding-left:100px;  margin-top:20px;
        dt { position:absolute; left:0; font-size:18px; font-weight: 400;}
        dd { margin-bottom:10px; font-size:18px; font-weight: 400;}
    }
}


}