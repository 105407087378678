@charset "utf-8";

/*  
  _mixin.scss  

  # 선언  (@content); 확장
  @mixin opacity($value) {
    opacity: $value;
    @content;
  }

  # 사용
  xxx {
    @include opacity(.5){
      확장 스타일
    }
  }

*/

/* opacity(1.0) == 100% */
@mixin opacity($value) {
  -webkit-opacity: $value;
  -moz-opacity: $value;
  filter:alpha(opacity=$value*100);
  opacity: $value;
}


@mixin box-sizing($type:border-box) {
  -webkit-box-sizing:$type;
  -moz-box-sizing:$type;
  -o-box-sizing:$type;
  -ms-box-sizing:$type;
  box-sizing: $type;
}

@mixin border-radius($radius) {
  -webkit-border-radius:$radius;
  -moz-border-radius:$radius;
  -ms-border-radius:$radius;
  border-radius:$radius;
}


/* 1줄 말줄임 */
@mixin ellipsis {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis
}
/* 멀티라인 말줄임 */
@mixin ellipsis-line($line) {
    overflow:hidden;
    text-overflow:ellipsis;
    display: -webkit-box;  -webkit-line-clamp: $line; -webkit-box-orient: vertical;
}
/* 텍스트 깨기 */
@mixin wordBreak-all(){
    white-space: normal;
    word-wrap: normal;
    word-break: break-all;
}
/* 텍스트 단어별 줄바꿈  */
@mixin wordBreak-word(){
    word-wrap: break-word;
    word-break: keep-all;
}

/* float 영역 유지 */
@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// generic transform( rotate(90deg) )
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// scale(1.0) == 100%
@mixin scale($scale) {
  @include transform(scale($scale));
}
// rotate(xxdeg)
@mixin rotate($deg) {
  @include transform(rotate($deg));
}

// shadow
@mixin shadow($x_px, $y_px, $blur_px, $alpha){
  // X Y bluer size / rgba
  //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: $x_px $y_px $blur_px 0 rgba(0,0,0, $alpha), $x_px $y_px $blur_px 0 rgba(0,0,0, $alpha);
}


// transition: width .35s ease-in-out;
// linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit;
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

//user-select (none)
@mixin user-select($select) {
  -moz-user-select: -moz-#{$select};
  -khtml-user-select: $select;
  -webkit-user-select: $select;
  -o-user-select: $select;
  user-select: $select;
}

//font-smoothing
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// 구분 bqr ( $top_px, $left_px, $size_px, $bgcolor)
@mixin list-div_dot($top_px, $left_px, $size_px, $bgcolor){
  &::before { content: ""; display:block; position: absolute; top:$top_px; left: $left_px; 
    width: $size_px; height:$size_px; background-color: $bgcolor;
  }
}
@mixin list-div_bar($top_px, $left_px, $size_px, $bgcolor){
  &::before { content: ""; display:block; position: absolute; top:$top_px; left: $left_px; 
    width: 1px; height:$size_px; background-color: $bgcolor;
  }
}

/* 
* before 속성을 이용한 vertical 정열
*/
@mixin before-middle(){ position: relative; font-size: 0;  
  &::before { content: ""; display: inline-block; width: 0; height: 100%; vertical-align:middle; }
}



/** 
 * 비율로 영역 확보  
 * bg-ratio ( $w, $h ) : ( 16:9 = 56.25%);
 */
 @mixin bg-ratio ( $w, $h ){
  $per : ($h / $w * 100) + 0%; 
  position: relative; display: block; width: 100%; height: 0; padding: 0; overflow: hidden;
  box-sizing: border-box;  background-size: cover;  background-position: center;
  padding-bottom:  $per; 
}

/* ================= function ================= */


/* 단위환산 / vw(10vw) */
@function vw($value){ //   1440px : ex = vw(44vw)
  /* @return $value / ($G-pcw * 0.01); */
  @return calc(($value) / ($G-pcw * 0.01)) + 0vw;
}

@function vwh($value){ //   1440px : ex = vw(44vw)
  /* @return $value / ($G-pcw * 0.01); */
  @return calc(($value) / ($G-pcw * 0.009)) + 0vh;
}

@function vwm($value){ //  360px  : ex = vw(44vw)
	/* @return $value / ($G-min * 0.01); */
  @return calc(($value) / ($G-min * 0.01)) + 0vw;
}
@function px($value){ //   1440px : ex = vw(44vw)
  @return ($value) + 0px;
}

$G-rem : 16;

@function rem($value){ //   1440px : ex = vw(44vw)
  @return ($value) / $G-rem  + 0rem;
}



@function per($value,$total){ 
  @return ($value / $total) * 100 + 0%;
}

