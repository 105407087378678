@charset "utf-8";

/* _variables.scss */

//root path
//$G-path: '../';
// $G-maxWidth   : 1899px;  // 디자안 최대크기  1900 
// $G-width      : 1439px;  // 사이트 컨텐츠 크기
// $G-wMobile    : 1023px;  // 미디어쿼리 모바일
 $G-minMobile  : 360px;   // 최소 모바일 

$G-pcw          : 1440;  // 디자인크기가이드:
$G-mbw          : 768;   // 디자인크기가이드:768 / 1024
$G-min          : 360;   // 디자인크기가이드:
//$G-maxWidth:   1920px;   // 디자안 최대크기         !확정
$G-width        : 1440px;   // 사이트 컨텐츠 크기

//$G-wTablet:    1024px;  // 미디어쿼리 테블릿
$G-maxWidth   : 1440px;   // 미디어쿼리 모바일     // 768px /  1024px   !확정
$G-wMobile    : 1024px;    // 미디어쿼리 모바일     // 768px /  1024px   !확정 > 변경 960px
$G-minWidth   : 360px;    // 미디어쿼리 최소 모바일    



//img path
$Gpath-img:  '../images/';

//body color
//$G-body-color: #2f2d2d;

// Color 
$colorMain: #4DADA7; // * Point color 
$colorSub : #DB1B1B; // * Sub color
$colorBGw : #f6f6f6; // *
$colorBGd : #F0F0F0; // * 
 

// fonts Name, color
$baseFT  : "Noto Sans KR";         // Noto Sans KR
$subFT   : "Roboto";  // Roboto
$colorP  : #21a099;  // 포인트 컬러 *
$colorB  : #111111;  // 강조 컬러 *
$colorB2 : #333333;  // 기본 컬러 *
$colorD  : #000;  // 기본 컬러 *
$colorS  : #777777;  // 서브 컬러 * 설명
$colorL  : #999999;  // 저체도 컬러, 
$colorPH : #aaaaaa;  // input:placeholder
$colorE  : #db1b1b;  // 에러 경고  *


// form Color
$formH   : 48px    ;       // text, select 높이
$formLgH : 56px    ;       // text, select 높이
$lineC_d : #B2B2B2 ;     // 라인 기본값 bcbcbc
$lineC_s : #3e73d1  ;    // 라인 성공
$lineC_e : #DB1B1B ;     // 라인 경고 에러 *
$lineC_f : #333333  ;    // 라인 포커스 
    

// table border
$borderTop : 1px solid #999;
$borderLine : 1px solid #cccccc;
$borderBottom : 1px solid #cccccc;
