.main_mb {
  position: relative;
  min-height: calc(100vh - 60px);
  background: #333;
}

.mobile_landing {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  line-height: 1.6;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding: 120px 0 60px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 110px;
    height: 100px;
    background: url("../../images/common/error_alert.png");
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateX(-50%);
  }
}

.mb_main {
  width: 100%;
  overflow: hidden;
  word-break: keep-all;
  line-height: 1.5;
  .section_inner {
    max-width: 800px;
    margin: 0 auto;
  }
  .s_title {
    font-size: 24px;
    font-weight: 900;
    word-break: keep-all;
  }

  
  .switch_img { position: relative;padding: 60px 0;
    img {width:100%;}
    .switch_img0 {}
    .switch_img1 {
      width:80%;
      margin-top:50px;
    }
  }
  #section_holo {
    background: linear-gradient(180deg, #000000 40%, #0a0701 100%);
    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      //   background: linear-gradient(180deg, #000000 0%, #0a0701 100%);
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
    .section_outer {
      min-height: 100vh;
    }
    .holo_title {
      font-size: 32px;
      font-weight: 900;
      color: #fff;
      text-align: center;
      padding-top: px(100);
      line-height: 1.5;
      padding-left: 30px;
      padding-right: 30px;
      .ef_glow {
        will-change: opacity;
        color: #fff; /* 텍스트 색상 */
        text-shadow: 0 0 8px rgba(20, 20, 255, 0.7),
          0 0 16px rgba(20, 20, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.5),
          0 0 40px rgba(0, 255, 255, 0.4), 0 0 50px rgba(0, 255, 255, 0.3);
      }
      
    }.mwc_btnWrap {
      margin:30px 0;
    }
    .img_wrap.main_catImg {
      text-align: center;
      margin-top: px(100);
      padding-bottom: 100px;
      padding-left: 30px;
      padding-right: 30px;
      img,
      video {
        width: 100%;
        max-width: 800px;
      }
    }
    .holo_txt {
      padding: 0 30px;

      p {
        font-size: 16px;
        color: #86868b;
        margin-bottom: px(30);
        line-height: 1.5;
        .txt_white {
          color: #fff;
        }
        &.desc {
          font-size: 14px;
          margin-top: 30px;
        }
      }

      ul {
        margin-top: 30px;
        li {
          padding-left: 10px;
          position: relative;
          color: #86868b;
          font-size: 14px;
          line-height: 1.5;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background: #86868b;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 11px;
            margin-top: -2px;
          }
        }
      }
    }
    .holo_wrap1 {
      padding-bottom: 100px;
      position: relative;
      .img_wrap {
        padding-top: 40px;
        img {
          width: 100%;
          transform: translateX(-20%);
        }
      }
    }
  }

  #section0 {
    .video_wrap {
      height: calc(100vh - 60px);
      width: 100%;
      overflow: hidden;
      position: absolute;
      &.clickable:after {
        display: none;
      }

      video {
        height: 100%;
        transform: translateX(-50%);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transform: translateX(-50%);
        margin-left: 50%;
      }
    }
    .section_inner {
      height: calc(100vh - 60px);
      position: relative;
      z-index: 1;
      padding: 32px;
      pointer-events: none;
      .s_title {
        color: #fff;
        text-shadow: 0px 0px 12px rgba(26, 255, 255, 0.6), 0px 0px 12px #000000;
      }
    }
  }
  #section1 {
    color: #fff;
    background: #000; // #2E2E2E;
    .section_inner {
      padding: 80px 32px;
      text-align: center;
      .video_box video {
        width: 100%;
        max-width: 500px;
        padding: 20px 0;
      }
      .frame_wrap {
        width: 100%;
        max-width: 500px;
        position: relative;
        margin: 40px auto 70px;
        .frame_inner {
          font-size: 0;
          box-shadow: rgba(0, 0, 0, 0.3) vw(5) vw(5) vw(20);
          outline: 1px solid transparent;
          backface-visibility: hidden;
          touch-action: pinch-zoom;
          will-change: transform;
          box-sizing: border-box;
          //&:after {content:''; position:absolute; left:50%; bottom:-30px;transform: translateX(-50%); width:40px; height:13px; background:url('../../images/common/icon_rotate.png'); background-size:100%; background-repeat: no-repeat;}
          canvas {
            width: 100%;
          }
        }
      }
      .s_desc {
      }
    }
    .lottiePlayer {
      position: absolute;
      width: 100px;
      margin-left: -50px;
      left: 50%;
      margin-top: 10px;
    }
  }
  #section1-1 {
    .section_inner {
      padding: 80px 32px;
      .section_wrap {
        text-align: center;
        .s_desc {
          margin-top: 30px;
        }
        .img_wrap {
          text-align: center;
          margin: 40px 0;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  #section2 {
    background: #f0f2f5;
    .section_inner {
      padding: 80px 32px;
      .section_wrap {
        text-align: center;
        .s_desc {
          margin-top: 30px;
        }
      }
    }
  }
  #section3 {
    background: #f0f2f5;
    .section_inner {
      padding: 80px 32px;
      .section_wrap {
        text-align: center;
        .s_desc {
          margin-top: 30px;
        }
      }
      .bs_slide_wrap {
        margin-top: 32px;
        .swiper-slide {
          position: relative;
          .img_wrap {
            overflow: hidden;
            border-radius: 20px;
            img {
              width: 100%;
            }
          }
          .txt {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 10px 13px;
            font-size: 12px;
            color: #fff;
            text-align: left;
          }
        }
      }

      .bs_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 100%;
          position: relative;
          background: #fff;
          border-radius: 20px;
          margin-top: 95px;
          .circle_wrap {
            text-align: center;
            border-radius: 50%;
            width: 150px;
            padding-bottom: 150px;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            background: #f00;
          }
          padding: 100px 35px 40px 35px;
          text-align: center;
          .cc_tit {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            font-weight: 900;
            color: #fff;
          }
          .tit {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 14px;
          }
          .txt {
            font-size: 14px;
            word-break: keep-all;
            line-height: 22px;
          }
        }
        li.bs_inner0 .circle_wrap {
          background: #004098;
        }
        li.bs_inner1 .circle_wrap {
          background: #558bcb;
        }
        li.bs_inner2 .circle_wrap {
          background: #7580bd;
        }
      }
    }
  }

  #section7 {
    background: #f0f2f5;
    .section_inner {
      padding: 80px 32px;
    }
    .section_wrap {
      text-align: center;
      .s_desc {
        margin-top: 30px;
      }
    }
    .s_title {
      color: #000;
      text-align: center;
    }
    .s_desc {
      margin-top: px(34);
      color: #000;
      text-align: center;
    }

    .b2b_wrap {
      margin: 0 auto;
      margin-top: px(55);
      background: #fff;
      border-radius: 20px;
      padding: 0 px(5);
      max-width: 500px;

      .b2b_inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .bs_box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            position: relative;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }

            img {
              width: 100%;
            }
          }
        }
      }
      & + .btn_wrap {
        margin-top: px(50);
      }
    }
  }
  #section4 {
    background: #f0f2f5;
    .section_inner {
      padding: 80px 32px;
      .section_wrap {
        text-align: center;
        .s_desc {
          margin: 40px 0;
        }
        .swiper {
          overflow: visible;
          .swiper-slide {
            width: 150px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
  #section5 {
    background: #f0f2f5;
    .section_inner {
      padding: 80px 32px;
      .section_wrap {
        .news_body {
          margin: 40px 0;
        }
        .swiper {
          overflow: visible;
          .swiper-slide {
            width: 191px;
            overflow: hidden;
            border-radius: 20px;
            background-color: #fff;
            .img_wrap {
              .img_inner {
                width: 191px;
                height: 125px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .cont_wrap {
              .cont_inner {
                padding: 13px 20px;
                word-break: break-all;
                height: 173px;
                position: relative;
                .tit {
                  font-weight: 700;
                  font-size: 18px;
                  @include ellipsis-line(4);
                }
                .date {
                  font-size: 16px;
                  font-weight: 400;
                  color: #c3cbcd;
                  position: absolute;
                  bottom: 13px;
                  left: 20px;
                }

                &:after {
                  content: "";
                  width: 19px;
                  height: 8px;
                  display: block;
                  background: url("../../images/common/news_arrow.png");
                  background-repeat: no-repeat;
                  background-size: 100%;
                  position: absolute;
                  bottom: 21px;
                  right: 13px;
                }
              }
            }
          }
          .swiper-button-prev {
            display: none;
          }
          .swiper-button-next {
            display: none;
          }
        }
        .btn_wrap {
          text-align: center;
        }
      }
    }
  }
  #section6 {
    .section_inner {
      max-width: none;
      padding: 74px 16px;
      color: #fff;
      background: url("../../images/main/bg_contact.jpg");
      background-size: cover;
      background-position: center center;
      .section_wrap {
        text-align: center;
        .s_title {
          margin-bottom: 35px;
        }
      }
    }
  }

  .main_mb_solutions {
    margin: 30px 0;
    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        .slt_cont_img {
          width: 100%;
          margin-bottom: 10px;
          border-radius: 20px;
          img {
            width: 100%;
          }
        }
        .slt_cont_txt {
          width: 100%;
          padding: 17px;
          border-radius: 20px;
          background: #f0f2f5;
          .tit {
            font-size: 14px;
            color: #004098;
            font-weight: 700;
          }
          .txt {
            font-size: 14px;
            color: #000;
            font-weight: 400;
            margin-top: 26px;
            line-height: 1.5;
            word-break: keep-all;
          }
        }
      }
    }

    .swiper {
      overflow: visible;
    }
    .mb_sol_item {
      width: 250px;
      overflow: hidden;
      border-radius: 20px;
      .img_wrap {
        .img_inner {
          width: 100%;
          height: 350px;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 60.94%,
              rgba(0, 0, 0, 0.7) 100%
            );
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .cont_wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #fff;
        text-align: left;
        padding: 20px;
        letter-spacing: -1px;
        .name {
          font-size: 18px;
          font-weight: 700;
        }
        .description {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .bs_acd {
    margin-top: 20px;
    text-align: left;

    .bs_acd_item {
      border-bottom: 1px solid #c3cbcd;
      .item_head {
        border-top: 1px solid #c3cbcd;
        position: relative;
        button {
          display: block;
          line-height: 1.4;
          font-weight: 700;
          font-size: 14px;
          padding: 15px 6px;
          width: 100%;
          text-align: left;
          padding-right: 36px;
        }
        &:after {
          content: "";
          width: 16px;
          height: 8px;
          background: url("../../images/common/acord_arrow.png");
          background-size: 100%;
          display: block;
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -4px;
          transform: rotate(180deg);
        }
      }
      .item_body {
        padding: 12px 6px 24px;
        display: none;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #000;
          line-height: 1.55;
          display: inline-block;
        }
      }
      &.on {
        .item_head {
          border-top: 1px solid #c3cbcd;
          button {
            color: #004098;
          }
          &:after {
            transform: rotate(0deg);
          }
        }
        .item_body {
          // display:block;
        }
      }
    }
  }
  .footer {
    margin-top: 0;
  }
}
