/* ui_common.scss */

/* ====== 버튼 ====== */
//a.btn, a.btn.btn_lg, a.btn.btn_sm, a.btn.btn_xs,
//button.btn, button.btn.btn_lg, button.btn.btn_sm, button.btn.btn_xs { font-size: 0; line-height: 0;}
.btn {  position: relative; box-sizing: border-box; 
  display: inline-block; padding:5px 15px 5px; margin-bottom: 0;    
  font-family: $baseFT;  font-weight: 600; font-size: 16px; line-height: 36px; text-align: center;
  letter-spacing: 0px; white-space: nowrap; vertical-align: middle;
  -ms-touch-action: manipulation;touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;
  user-select: none; background-image: none;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;  
  span {font-family: $baseFT; color: $colorB; font-weight: 600; font-size: 16px; line-height: 36px; box-sizing: border-box; vertical-align: middle;}
  i { width: 36px; margin-left: 0px; vertical-align: middle;
    &::before { font-size: 16px; } 
  }
  span + i { margin-left: 0px; }
}
@media all and (max-width:$G-wMobile)  {
  .btn {
    font-size: 14px; padding:3px 15px 3px; line-height: 35px; 
    span {  font-size: 14px; }
  } 
}
// 비활성화
.btn:disabled { @include opacity(0.5); pointer-events:none; }


// 색상 *
.btn.btn_point { 
  background:#4DADA7; border:1px solid #4dada7; color:#fff; span { color:#fff;}}
.btn.btn_point:hover {  background:#089B93;  border-color: #089B93;}
.btn.btn_point:disabled, .btn.btn_point.disabled { pointer-events:none; 
  @include opacity(1);
  background:#e4e4e4;  border-color:#e4e4e4; span {color:$colorL;}
}

.btn.btn_black { 
  background:#333333; border:1px solid #333333; color:#fff; span { color:#fff;}}
.btn.btn_black:hover {  background:#000;  border-color: #000;}

.btn.btn_default { 
  background:transparent; border:1px solid #333333; color:#333333;
}
.btn.btn_default:hover {  background:#F0F0F0; }

.btn.btn_point_line { 
  background:#fff; border:1px solid #4DADA7; color:#555555;
}
.btn.btn_point_line:hover {  background:#4DADA7; color:#fff; }

.btn.btn_blue { transition: .2s all;
  background:transparent; border:1px solid #004098; color:#004098;
}
.btn.btn_blue:hover { background:#004098; color:#fff;}


.btn.btn_blue2 { transition: .2s all;
  background:#004098; color:#fff; border:1px solid #004098; ;
}
.btn.btn_blue2:hover { background:#fff; color:#004098;}

.btn.btn_white { 
  background:#fff; border:1px solid #fff; color:#333;}
.btn.btn_white:hover { background:#F0F0F0; border-color:#F0F0F0;}

.btn.btn_white_line { transition: .2s all;
  background:transparent; border:1px solid #fff; color:#fff;}
.btn.btn_white_line:hover { background:rgba(255,255,255,0.3); border-color:#F0F0F0;}

.btn.btn_blue_line {
  background:transparent; border:1px solid #004098; color:#004098;}
.btn.btn_blue_line:hover { background:transparent; border-color:#004098;}




.btn.btn_gray_line { 
  background:transparent; border:1px solid #BDBDBD; color:#555;}
.btn.btn_gray_line:hover {  background:#F0F0F0; color: #333; }

.btn.btn_gray { 
  background:#E4E4E4; border:1px solid #e4e4e4; color:#333;}
.btn.btn_gray:hover { background:#CDCDCD; border-color:#CDCDCD;}

.btn.btn_light_gray_line { background:#f6f6f6; border:1px solid #bdbdbd; color:#555;
  span { color:#555;}
}
.btn.btn_light_gray_line:hover { background:#F0F0F0; border-color:#bdbdbd;}


 
// 크기 *
.btn.btn_full { padding:9px 20px 9px;  width: 100%; 
  font-size: 18px; line-height: 36px; font-weight: 600;
  span { font-size: 18px; line-height: 36px;}
}
@media all and (max-width:$G-wMobile)  {
  .btn.btn_full { padding:8px 20px 8px;  min-width: auto; 
    font-size: 15px;  line-height: 35px;
    span { font-size: 15px;  line-height: 35px;}
  }
}

.btn.btn_lg { padding:9px 20px 9px;  min-width: 200px; 
  font-size: 18px; line-height: 36px; font-weight: 600;
  span { font-size: 18px; line-height: 36px;}
}
@media all and (max-width:$G-wMobile)  {
  .btn.btn_lg { padding:8px 20px 8px;  min-width: auto; 
    font-size: 15px;  line-height: 35px;
    span { font-size: 15px;  line-height: 35px;}
  }
}

.btn.btn_sm { padding: 12px 18px;   min-width: auto;  
  font-size: 18px; line-height: 16px; font-weight: 400; letter-spacing: -0.5px;
}
@media all and (max-width:$G-wMobile)  {
  .btn.btn_sm { padding: 12px 18px;  min-width: auto; 
    font-size: 14px;  line-height: 16px;
    span { font-size: 14px;  line-height: 16px;}
  }
}



.btn.btn_sm_vw { padding: vw(12) vw(15);   min-width: auto;  
  font-size: vw(18); line-height: vw(16); font-weight: 400; font-family: $subFT; letter-spacing: vw(-0.5);
}
@media screen and (min-width:$G-maxWidth) {
  .btn.btn_sm_vw { padding: 12px 18px;   min-width: auto;  
    font-size: 18px; line-height: 16px; letter-spacing: -0.5px;
  }
}
@media all and (max-width:$G-wMobile)  {
  .btn.btn_sm_vw { padding: 2px 20px 2px;  min-width: auto; 
    font-size: 13px;  line-height: 26px;
    span { font-size: 13px;  line-height: 26px;}
  }
}
@media all and (max-height:1000px)  {
  .btn.btn_sm_vw { padding: vwh(12) vwh(15); 
    font-size: vwh(18); line-height: vwh(16);  letter-spacing: vwh(-0.5);
  }
}


/* .btn.btn_xs { padding: 3px 20px 3px;   min-width: auto;  
  font-size: 20px; line-height: 24px; height: 31px;
  span { font-size: 20px; line-height: 24px; }
}
@media all and (max-width:$G-wMobile)  {
  .btn.btn_xx { padding: 2px 10px 2px;  min-width: auto; 
    font-size: 15px;  line-height: 19px;
    span { font-size: 15px;  line-height: 19px; }
  }
} */




// 버튼 정열
.btn_bottom { margin-top: 40px;}
.btn_set-float { @include clearfix(); 
  &.tac { text-align: center;}
  .btng-l  { float: left; }
  .btng-r  { float: right;}
}

.btn_set { display: flex; 
  .btn_item { flex: 1; text-align: center; 
    .btn { width: 100%;}
  }
}
.btn_set_more { text-align: center;
  .btn { width: 170px;}
  @media all and (max-width:$G-wMobile)  {
    .btn { width: 160px;}
  }
}



/* 리스트 뷰일때 */
.type-width_list {
  .scroll_top-box { 
    .box-inner { position: relative; padding: 0 60px; max-width: 1920px; width: 100%; margin:0 auto;
      text-align: right;}
      @media all and (max-width:$G-width)  {
        .box-inner { margin-top: 80px;padding:0 50px; max-width: 1420px; }
      }
      @media all and (max-width:$G-wMobile)  { 
        .box-inner { padding: 0 25px; }
      }
  }
}


/* ====== PAGING ====== */    
.paging-area { width:100%; clear:both;position:relative; margin:20px 0 0px; 
  letter-spacing:0px; text-align:center;
  .paging { position:relative; text-align:center; font-size: 0; }
  .paging a,
  .paging strong { position:relative; display:inline-block; color:$colorD;
    min-width: 34px; height:34px; line-height:34px; 
    text-decoration:none; vertical-align:top; padding:0 10px; margin: 0 2px;
    font-family: $baseFT; font-size: 16px; font-weight: 300;
    border:1px solid transparent; box-sizing: border-box;
    em { font-style: normal; }
    &:after {  }
  }

  .paging a.prev, .paging a.prev_end, .paging a.next, .paging a.next_end {
    border:1px solid transparent; box-sizing: border-box;
    &:after { content:""; display: block; position: absolute; top:6px; left:7px; }
  }
  .paging a.prev { width: 34px;  padding:0px;  text-indent: -9999px;
    &:after {  } 
  }
  .paging a.prev_end { width: 34px;  padding:0px;   text-indent: -9999px;
    &:after {  }
  }
  .paging a.next { width: 34px;  padding:0px;  text-indent: -9999px;
    &:after {  }
  }
  .paging a.next_end { width: 34px;  padding:0px;   text-indent: -9999px;
    &:after {  }
  }

  .paging strong.on { background:#ededed; color:$colorB2; font-weight: 600; 
    border:1px solid #ededed; 
  }
  .paging a:hover, .paging a:focus ,
  .paging a.next:hover, .paging a.next_end:hover , 
  .paging a.prev:hover, .paging a.prev_end:hover { 
    border:1px solid #ededed; 
  }
  @media all and (max-width:$G-wMobile)  {
     margin:0px 0 10px; letter-spacing:0px; text-align:center;
    .paging a,
    .paging strong { 
      min-width: 32px; height:32px; line-height:32px; 
      padding:0 10px; margin: 0 1px;
      font-size: 14px;
    }
  
    .paging a.prev, .paging a.prev_end, .paging a.next, .paging a.next_end {
      &:after { content:""; display: block; position: absolute; top:5px; left:5px; }
    }
    .paging a.prev     { width: 32px; }
    .paging a.prev_end { width: 32px; }
    .paging a.next     { width: 32px; }
    .paging a.next_end { width: 32px; }
  }
   
}


/* ================== TAB MENU */
.tab-wrap { position: relative; }
.tab-list { display: inline-block; position:relative;
  margin:0; padding:0; width:100%; 
  >li { display:inline; width:100%; list-style:none; box-sizing: border-box; 
    .btn-area{ position:absolute; height:50px; display:block;
      text-decoration:none; text-align: center;  color:#000;
      a { font-size: 18px; font-weight: bold;background-color: #dadada;
        .tab_name {}
        @include wordBreak-all();    
      }
    }	
    .st_table{ display: table; width: 100%; height: 100%;
      a { display: table-cell; vertical-align: middle; }
    }
  }	
  >li >.con-area{ display: none; 
    width:100%; margin-top:50px; margin-left:0px; left:0px; 
    border: 0px solid #ccc; padding:0 0px}	
  >.on .btn-area a { color:#ffffff; background-color: $colorP;}
  >.on > .con-area { display: block;}
  
  /* ================== TAB MENU tab_type1 */
  &.tab_type1 { 
    >li {  background: #eaeaea;
      .btn-area{ color:$colorD; 
        border: 1px solid #d0d0d0; border-bottom:2px solid $colorP; border-left:none;
        a { background-color: #eaeaea; }
      }	
      .st_table{
        a { }
      }
      &:first-child  .btn-area{ border-left:1px solid #d0d0d0;}
    }	
    >li >.con-area{ }	
    >.on .btn-area {
      border: 2px solid $colorP !important; border-bottom: none !important;
      a { color:$colorB; font-weight: bold;background-color: #fff;}
    }
    >.on > .con-area { }
    >.on_prev { 
      .btn-area{border-right: 0px solid transparent;}
    }
  }

  /* ================== TAB MENU setting */
  &.tab_col2 { 
    >li .btn-area { top:0; left:0;   width:50%; }
    >li:nth-child(1) .btn-area{ left:0;  }
    >li:nth-child(2) .btn-area{ left:50%;}
  }
  &.tab_col3 { 
    >li .btn-area { top:0; left:0; width:33.333333333%; border-left:1px solid #cccccc;}
    >li:nth-child(1) .btn-area{ left:0; border-left:none;}
    >li:nth-child(2) .btn-area{ left:33.3333333333%; } 
    >li:nth-child(3) .btn-area{ left:66.6666666666%; }
  }
  &.tab_col4 { 
    >li .btn-area { top:0; left:0;   width:25%; }
    >li:nth-child(1) .btn-area{ left:0;       }
    >li:nth-child(2) .btn-area{ left:25%; }
    >li:nth-child(3) .btn-area{ left:50%; }
    >li:nth-child(4) .btn-area{ left:75%; }
  }
  &.tab_col5 { 
    >li .btn-area { top:0; left:0;   width:20%; }
    >li:nth-child(1) .btn-area{ left:0;       }
    >li:nth-child(2) .btn-area{ left:20%; }
    >li:nth-child(3) .btn-area{ left:40%; }
    >li:nth-child(4) .btn-area{ left:60%; }
    >li:nth-child(5) .btn-area{ left:80%; }
  }
}



.btn_top {
  display:none; width:0px; height:0px; position: fixed;
}
@media all and (max-width:$G-wMobile)  {
  
  .btn_top {
    width:40px; height:40px; background: url('../../images/common/top.png'); background-size:100%; background-repeat: no-repeat; display:block; position: fixed; bottom:23px; right:16px; z-index: 10;
    display:none;
  }
}