
  select,button,input, textarea {
    font-family: $baseFT, Malgun Gothic, dotum,"돋움",Arial,AppleGothic, Sans-serif; 
  }
  /* ======================== input text  */
  /* placeholder */
  // ie
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"], 
  textarea { &.placeholdersjs {color: $colorPH;} }
  // Internet Explorer 10 이상에 적용select2Basic42
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"], 
  textarea { &:-ms-input-placeholder {color: $colorPH;} } 
  //  Webkit, Blink, Edge에 적용
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"], 
  textarea { &::-webkit-input-placeholder {color: $colorPH;} }
  // Mozilla Firefox 4 부터 18에 적용
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"], 
  textarea { &::-moz-placeholder    {color: $colorPH;} }
  // input style
  em.star { display: inline-block; height: 14px; padding: 0 5px; color: #f24848; font-weight: bold; }
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"] {
    width: 100%; height: 55px; padding: 11px 17px;
    font-size: 18px; vertical-align: middle;
    border: 1px solid #fff; outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  textarea {
    width: 100%; padding: 11px 17px;
    font-size: 18px; vertical-align: middle;
    border: 1px solid #fff; outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  @media all and (max-width:$G-wMobile)  { 
    input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"] {
      font-size: 14px; padding: 5px 15px 5px; line-height: 22px; height: 43px;
    }
  }

  
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"]
  { &.h56 { height:56px; } }
  @media all and (max-width:$G-wMobile)  { 
    input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"] {
      &.h56{
        font-size: 13px; padding: 5px 15px 5px; line-height: 22px; height: 53px;
      }
    }
  }
  
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"]
  { &.h42 { height:42px; font-size: 16px; } }
  @media all and (max-width:$G-wMobile)  { 
    input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"] { 
      &.h42 { font-size: 13px; padding: 5px 15px 5px; line-height: 22px; height: 43px;}
    }
  }
  
  
  /* ======================== focus, ,disabled, input_error */
  /* focus */
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"]
  { &:focus , &:hover {border-color: $lineC_f;} }
  /* read only,  disabled */
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"] { 
    &:readonly, &.readonly, &:disabled, &.disabled { background-color : $colorBGw;  color: $colorPH;
      &+i:first-child { z-index: 1;}
    }
  }
  /* error */
  input[type="text"], input[type="password"], input[type="number"], input[type="url"], input[type="email"], input[type="search"]
  { &.input_error {border-color: $lineC_e;} }
  


  /* ========================  input radio */
  .trp.radio-box +  .trp.radio-box  { margin-left: 40px; }
  .trp.radio-box { position: relative; display: inline-block;  padding-left: 30px;  min-height: 24px;
    label { display: inline-block; height: 24px; margin-left: 0px; 
      font-size: 18px; font-weight: 500; line-height: 24px;
    }
    em { display: inline-block; height: 20px;    text-indent: 0px; }
    input[type="radio"] {  cursor: pointer;position: absolute; top:0; left: 0; /*  width:100%; */ width: 24px; height: 24px; margin: 0; padding: 0;  opacity: 0; filter: alpha(opacity=0); z-index: 1;  }    
    input[type="radio"] + i { cursor: pointer; display: inline-block; position: absolute; top: 2px; left: 0px;  }
    input[type="radio"] + i:after {  cursor: pointer; display: block; content: '';  width:20px; height:20px; display:block; background-image:url('../../images/common/form-radio-off.png'); background-repeat: no-repeat; background-size:100%; }
    input[type="radio"]:checked + i:after {  background-image:url('../../images/common/form-radio-on.png'); }
    input[type="radio"]:disabled + i      {  @include opacity(0.5);
      &:after { display: block; content: '';   }
    }
    input[type="radio"]:disabled + i +label  {  @include opacity(0.5); }
    @media all and (max-width:$G-wMobile)  {  padding-left: 25px; min-height: 17px;
      & + .trp.radio-box  { margin-left: 30px; }
      label { height: 17px; 
        font-size: 14px; line-height: 17px;
      }
      input[type="radio"] { width: 17px; height: 17px; }    
      input[type="radio"] + i:after { display: block; content: '';  width:17px; height:17px; }
      input[type="radio"]:checked + i:after {   }
    }
  }
  

  
/* ========================  input checkbox */
.trp.checkbox-box { position: relative; display: inline-block;  padding-left: 25px; min-height: 20px;
  label { display: inline-block; line-height: 20px; margin-left: 3px;  vertical-align: top; cursor: pointer; }
  em { display: inline-block; height: 20px;     text-indent: 0px;}
  input[type="checkbox"] {  cursor: pointer;position: absolute; top:0; left: 0;  /*  width:100%; */ width: 20px; height: 20px; margin: 0; padding: 0;  opacity: 0; filter: alpha(opacity=0); z-index: 1; }    
  input[type="checkbox"] + i {  cursor: pointer;display: inline-block; position: absolute; top: 1px; left: 0px; }
  input[type="checkbox"] + i:after { display: block; content: '';  cursor: pointer; width:20px; height:20px; display:block; background-image:url('../../images/common/form-checkbox-off.png'); background-repeat: no-repeat; background-size:100%; }
  input[type="checkbox"]:checked + i:after {   background-image:url('../../images/common/form-checkbox-on.png'); }
  input[type="checkbox"]:disabled + i      {  @include opacity(0.5);
      &:after { display: block; content: '';  /* @include sp_icons($form-checkbox-dim); */ }
  }
  input[type="checkbox"]:disabled + i +label  { @include opacity(0.5);  /* color:$cg5; */ }
}

