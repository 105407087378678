/* */
/* 
@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);     
@import url(//cdn.rawgit.com/hiun/NanumSquare/master/nanumsquare.css);     
 */
 @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
 
// 기본 속성
.ft_t {  font-family: $baseFT;  font-weight: 100; }   // Thin
.ft_l {  font-family: $baseFT;  font-weight: 300; }   // Light
.ft_r {  font-family: $baseFT;  font-weight: 500; }   // Regular
.ft_m {  font-family: $baseFT;  font-weight: 500; }   // Medium
.ft_sb {  font-family: $baseFT;  font-weight: 600; }  // SemiBold
.ft_b {  font-family: $baseFT;  font-weight: 700; }   // Bold
.ft_eb {  font-family: $baseFT;  font-weight: 800; }  // ExtrBold
.ft_bb{  font-family: $baseFT;  font-weight: 900; }   // Black



/* 국문 폰트 선언 */
:lang(ko) { }
.th1 { font-size: 42px; font-weight: 600; line-height: 1.36; } 

.tt1 { font-size: 42px; font-weight: 600; line-height: 1.36; } 
.tt2 { font-size: 32px; font-weight: 700; line-height: 1.36; } 
.tt3 { font-size: 26px; font-weight: 700; line-height: 1.36; } 
.tt4 { font-size: 24px; font-weight: 600; line-height: 1.36; } 
.tt5 { font-size: 20px; font-weight: 600; line-height: 1.36; } 
.tt6 { font-size: 18px; font-weight: 500; line-height: 1.36; } 

.tb1 { font-size: 18px; font-weight: 500; line-height: 1.78; } 
.tb2 { font-size: 16px; font-weight: 500; line-height: 1.78; } 

@media all and (max-width:$G-wMobile)  {
  .th1 { font-size: 25px; font-weight: 600; line-height: 1.36; } 

  .tt1 { font-size: 25px; font-weight: 600; line-height: 1.36; } 
  .tt2 { font-size: 22px; font-weight: 700; line-height: 1.36; } 
  .tt3 { font-size: 18px; font-weight: 700; line-height: 1.36; } 
  .tt4 { font-size: 17px; font-weight: 600; line-height: 1.36; } 
  .tt5 { font-size: 15px; font-weight: 600; line-height: 1.36; } 
  .tt6 { font-size: 14px; font-weight: 500; line-height: 1.36; } 

  .tb1 { font-size: 14px; font-weight: 500; line-height: 1.5;  } 
  .tb2 { font-size: 13px; font-weight: 500; line-height: 1.5;  } 
}