.tech_header {
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  height: 60px;
  .header_inner {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 40px;
    img {
      height: 20px;
    }
    .abr {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
      .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: #333;
      }
      .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
      .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
      .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
        border-color: #333;
      }
    }
  }
}

.tech_solution {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px;
  min-height: calc(100vh - 321px);
  margin-bottom: 100px;
  .tech_inner {
    .tech_title {
      font-size: 3.4em;
      font-weight: bold;
      margin: 50px 0 100px;
      text-align: center;
    }
    .tech_search {
      max-width: 800px;
      margin: 0 auto;
      position: relative;
      input {
        width: 100%;
        border: none;
        height: 50px;
        padding: 0 30px;
        box-sizing: border-box;
        background: #eee;
        border-radius: 25px;
        font-size: 1em;
        &:focus {
          background: #e7e7e7;
        }
        &:hover {
          border: none;
        }
      }
    }
    .tech_category {
      margin-top: 50px;
      .cate_wrap {
        margin-top: 24px;
        .check-wrap {
          position: relative;
          display: inline-block;
          margin-right: 15px;
        }
        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          border: none;
          background: 0 0;
          & + label {
            display: block;
            position: relative;
            height: 40px;
            padding: 5px 20px 8px;
            line-height: inherit;
            border-radius: 20px;
            text-align: center;
            border: 1px solid #000;
            box-sizing: border-box;
            cursor: pointer;
            span {
              display: inline-block;
              font-size: 16px;
              font-weight: 500;
              line-height: 26px;
            }
          }
          &:checked + label {
            color: #fff;
            background-color: #000;
          }
        }
      }
    }
    .errorcode {
      padding: 0 30px;
      color: #f92727;
    }
    .tech_manual {
      margin: 100px 0;
      .sub_desc {
        color: #666;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .manual_wrap {
        background: #eee;
        border-radius: 20px;
        padding: 30px;
        margin-top: 30px;
      }
      .manual_list {
        li {
          padding: 5px 0;
          a {
            font-weight: 500;
            color: #333;
          }
          a:hover {
            text-decoration: underline;
            color: #000;
          }
        }
      }
    }
    .tech_form {
      margin-bottom: 100px;
      .rs-form-horizontal .rs-form-group .rs-form-control-label {
        text-align: left;
        position: absolute;
        left: 0;
        top: 0;
      }
      .rs-form-group {
        padding-left: 180px;
        .rs-input {
          border: 1px solid #333;
          padding: 6px;
          height: auto;
          width: 100%;
          max-width: 300px;
          font-size: 15px;
        }
        textarea.rs-input {
          max-width: none;
        }
        &.btn_wrap {
          padding-left: 0;
          .btn_black {
            background-color: #333;
            &.rs-btn-primary:hover,
            .rs-btn-primary:focus {
              background-color: #111;
            }
          }
        }
      }
      .require {
        display: none;
      }
      .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
        width: 100%;
        float: none;
      }
      .rs-form-horizontal
        .rs-form-group
        .rs-form-control-wrapper
        + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
        margin-left: 0;
        color: #f92727;
        margin-top: 5px;
      }
      .rs-btn {
        padding: 12px 40px;
      }
    }
    .tech_list {
      margin-top: 100px;
      .rs-table-cell-header .rs-table-cell-content {
        //font-size:1em; padding: 20px;
      }
      .list_title_wrap {
        position: relative;
        border-bottom: 1px solid #333;
        padding-bottom: 15px;
        .list_count {
          position: absolute;
          right: 0;
          bottom: 15px;
          font-size: 1em;
          font-weight: 500;
          color: #333;
        }
      }
      .list_cont_wrap {
        width: 100%;
        margin-bottom: 100px;

        thead {
          border-bottom: 1px solid #eaeaea;
          tr {
            th {
              text-align: center;
              font-size: 1em;
              padding: 20px;
              &.tit {
                text-align: left;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 1em;
              padding: 20px;
              border-bottom: 1px solid #eaeaea;
              color: #666;
              font-weight: 400;
              &.model {
                text-align: center;
                width: 15%;
                min-width: 150px;
              }
              &.tit {
                font-weight: 500;
                color: #333;
                a:hover {
                  text-decoration: underline;
                  color: #000;
                }
              }
              &.date {
                text-align: center;
                width: 15%;
                min-width: 150px;
              }
            }
          }
        }
        tfoot {
          tr {
            td.page {
              padding: 30px 0;
              text-align: center;
              a {
                display: inline-block;
                padding: 0px 5px;
                margin: 0 10px;
                color: #666;
                &.current {
                  font-weight: 700;
                  color: #000;
                  border-bottom: 1px solid #000;
                }
              }
            }
          }
        }
      }
    }
    .view_wrap {
      &.write_mode {
        .rs-radio-group {
          font-size: 18px;
        }
        .rs-radio-control {
          margin-top: 3px;
        }
        span.title {
          font-size: 18px;
          margin: 15px 0;
          display: inline-block;
        }
        input {
          border: 1px solid #333;
        }
        textarea {
          border: 1px solid #333;
          font-size: 18px;
          padding: 30px;
        }
        .view_title {
          border-bottom: none;
          .rs-row {
            margin-bottom: 20px;
          }
        }
        .view_footer {
          .view_btn_wrap {
            text-align: center;
            button {
              margin: 0 10px;
            }
          }
        }
      }
      .view_title {
        border-bottom: 1px solid #333;
        padding-bottom: 10px;
        .tit {
          font-size: 1.7em;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .date {
          font-size: 1em;
          color: #999;
          font-weight: 400;
        }
      }
      .view_cont {
        padding: 50px 30px;
        line-height: 1.8;
        ol.decimal > li {
          list-style: decimal;
          margin-left: 20px;
          margin-bottom: 30px;
          padding-left: 10px;
        }
        ol.circle > li {
          list-style: circle;
          margin-left: 20px;
          margin-bottom: 10px;
          padding-left: 0px;
        }

        .img_wrap {
          padding: 20px 0;
          img {
            max-width: 100%;
          }
        }
        a {
          color: rgb(0, 102, 255);
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .help {
        margin-top: 100px;
        position: relative;
        padding: 60px 250px 60px 180px;
        background: #f5f5f5;
        border-radius: 20px;
        line-height: 1.8;
        font-size: 1.2em;
        .btn_help {
          position: absolute;
          right: 100px;
          top: 50%;
          margin-top: -25px;
          background: #fff;
          text-align: center;
          border: 1px solid #333;
          padding: 7px 30px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 100px;
          margin-top: -27px;
          width: 54px;
          height: 48px;
          opacity: 1;
          background-size: 24px auto;
          background: url(/images/tech/question-icon.svg) no-repeat center
            center;
        }
      }
    }
  }
  .relevant {
    margin-top: 100px;
    .rel_title {
      font-size: 2em;
      font-weight: bold;
      padding-bottom: 20px;
      border-bottom: 2px solid #333;
    }
    .rel_list {
      li {
        padding: 20px;
        border-bottom: 1px solid #eaeaea;
        a {
          font-weight: 500;
          color: #333;
        }
        a:hover {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }
  .sub_tit {
    font-size: 1.7em;
    font-weight: bold;
  }
  .form_tit {
    font-size: 1.7em;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
    margin-bottom: 50px;
  }
}

.btn-search {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 48px;
  opacity: 1;
  background-size: 24px auto;
  background: url(/images/tech/icon_search_24.svg) no-repeat center center;
}
.blind,
caption,
legend {
  font-size: 0 !important;
  text-indent: 100%;
  position: absolute;
  z-index: -10;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  font-size: medium;
  line-height: normal;
  word-break: break-all;
}

.highlight {
  .codeHeader {
    box-sizing: content-box;
    background-color: #404040;
    color: #e6e6e6;
    border: 1px solid #404040;
    min-height: 30px;
    border-bottom: 0;
    flex-direction: row;
    margin-top: 16px;
    font-size: 0.8rem;
    display: flex;
    > .language {
      flex-grow: 1;
      align-items: center;
      padding: 2px 16px;
      display: flex;
    }
    & + pre {
      margin-top: 0;
      & > code {
        border: 0;
        padding: 0;
        line-height: 1.3571;
        display: block;
        position: relative;
      }
    }
  }
  pre {
    border: 0.125rem solid #404040;
    background-color: #2f2f2f;
    font-smooth: auto;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    tab-size: 4;
    -webkit-hyphens: none;
    hyphens: none;
    -webkit-overflow-scrolling: touch;
    margin: 1rem 0 0;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.3571;
    overflow: auto;
  }
}
.theme-dark .hljs-pscommand,
.theme-dark .hljs-keyword,
.theme-dark .hljs-selector-tag,
.theme-dark .hljs-built_in,
.theme-dark .hljs-name,
.theme-dark .hljs-tag,
.theme-high-contrast .hljs-pscommand,
.theme-high-contrast .hljs-keyword,
.theme-high-contrast .hljs-selector-tag,
.theme-high-contrast .hljs-built_in,
.theme-high-contrast .hljs-name,
.theme-high-contrast .hljs-tag,
.theme-dark .hljs-literal,
.theme-high-contrast .hljs-literal {
  color: #569cd6;
}
.theme-dark .hljs-parameter,
.theme-dark .hljs-type,
.theme-dark .hljs-attribute,
.theme-dark .hljs-title,
.theme-dark .hljs-addition,
.theme-dark .hljs-selector-attr,
.theme-dark .hljs-selector-pseudo,
.theme-dark .hljs-meta,
.theme-high-contrast .hljs-parameter,
.theme-high-contrast .hljs-type,
.theme-high-contrast .hljs-attribute,
.theme-high-contrast .hljs-title,
.theme-high-contrast .hljs-addition,
.theme-high-contrast .hljs-selector-attr,
.theme-high-contrast .hljs-selector-pseudo,
.theme-high-contrast .hljs-meta {
  color: #01cfff;
}
.theme-dark .hljs-string,
.theme-dark .hljs-string .hljs-subst .hljs-string,
.theme-dark .hljs-section,
.theme-dark .hljs-attribute,
.theme-dark .hljs-template-tag,
.theme-dark .hljs-template-variable,
.theme-dark .hljs-deletion,
.theme-dark .hljs-type,
.theme-dark .hljs-code,
.theme-high-contrast .hljs-string,
.theme-high-contrast .hljs-string .hljs-subst .hljs-string,
.theme-high-contrast .hljs-section,
.theme-high-contrast .hljs-attribute,
.theme-high-contrast .hljs-template-tag,
.theme-high-contrast .hljs-template-variable,
.theme-high-contrast .hljs-deletion,
.theme-high-contrast .hljs-type,
.theme-high-contrast .hljs-code {
  color: #ce9178;
}
.mx-w800 {
  max-width: 800px;
}
.mx-w900 {
  max-width: 900px;
}
.mx-w1000 {
  max-width: 1000px;
}
