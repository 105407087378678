

.main { 
  #fullpage {
    opacity: 0; 
    &.fullpage-wrapper.init {
      opacity: 1; transition: .2s opacity;
    }
  }
  .fp-tableCell { display:block;}
  .section_inner { 
    max-width: 1440px; margin:0 auto; //padding:0 40px; 
  }
  .s_title {
    position:relative; font-size:vw(50); font-weight: 900; line-height: 1.48; overflow: hidden;
    .txt_split { 
      transition: .4s all ease-out; transform: translateY(100%); display:inline-block;
    }
  }
  .s_desc { 
    position:relative;  line-height: 1.58; word-break: keep-all;font-size:vw(18); 
    .desc_txt {overflow: hidden;
      .txt {
        transition: .4s all ease-out; transform: translateY(100%); display:inline-block; opacity: 0.3;
      }
    }
  }


  .section.active {
    .s_title {
      .txt_split { 
        transform: translateY(0);
      }
    }
    .s_desc { 
      .desc_txt {
        .txt {
          transform: translateY(0); opacity: 1;
        }
      }
    }
    .js-text_rot {
      .tit {
        transform-origin: 0.859px 41.5781px;
        transform: translate(0px, 0%);
      }
    }
  }
  .js-text_rot { overflow:hidden;
    .tit {
      transition: .6s all ease-out; transition-delay: .2s;
      transform-origin: 0px 0px;
      transform: translate(0px, 120%) rotate(30deg);
    }
  }
  
  .frame_wrap {
   font-size:0; 
    width:vw(760); position:relative; margin:70px auto;
    //transition: .05s transform;
    .frame_inner { border-radius: vw(20); 
      //overflow:hidden;
      transform: perspective(900px) rotateX(-4deg) rotateY(-14.5934deg);
      box-shadow: rgba(0,0,0,0.3) vw(5) vw(5) vw(20);
      outline: 1px solid transparent;
      backface-visibility: hidden;
      touch-action: pinch-zoom;
      will-change: transform;
      //border-radius: 10px; overflow:hidden; border:20px solid #000;
      box-sizing: border-box;
      &:after {content:''; position:absolute; left:50%; bottom:vw( -30);transform: translateX(-50%); width:vw(40); height:vw(13); background:url('../../images/common/icon_rotate.png'); background-size:100%; background-repeat: no-repeat;}
    }
    img, canvas,video {width:100%;}
  }



  .video_wrap {
    position:absolute; top:0;left:0;right:0; bottom:0; overflow: hidden;
    video, iframe {
      position:absolute; top:50%;left:50%;width:100%; transform: translate(-50%, -50%);
    }
    img {
      object-fit: cover; width: 100%; height:100%;
    }
  }
  #section0 { 
    .fp-tableCell { overflow:hidden;}
    .section_wrap {position:relative; z-index: 2; }
    .s_title { color:#fff; margin-top:vw(230);
      text-shadow: 0px 0px 12px rgba(26, 255, 255, 0.6), 0px 0px 12px #000000;
    }
    .s_desc { margin-top:vw(67); color:#fff; }
  }
  #section1 { background: #2e2e2e; 
    .section_inner { min-height:1100px;position:relative; height:100vh;}
    .section_wrap {position:absolute; top:50%; transform: translateY(-50%); width:100%;}
    .s_title { color:#fff; text-align: center;}
    .s_desc { text-align: center; margin-top:vw(36);  color:#fff; }
  }
  #section2 { 
    .section_inner { /*padding:0 18px;*/ position:relative; height:100vh; min-height:1100px;}
    .section_wrap {padding-top:0; position:absolute; top:50%; width:100%; transform: translateY(-50%); }

    .s_title {color:#000; text-align: center; }
    .s_desc { text-align: center; margin-top:vw(40); color:#000;}
    .btn_wrap { margin-top:vw(30);}
    .solution_list { margin-top:vw(40);perspective: 1800px; padding: 0 vw(60);
      display:flex; flex-wrap: wrap; justify-content: space-between;
      li { width:vw(427); position:relative; backface-visibility: hidden;transform-style: preserve-3d;
        &:hover {
          .img_wrap img { transform: scale(1.06);}
        }
        .img_wrap { border-radius: vw(30); overflow: hidden; position:relative;
          &:after {
            content:''; position: absolute; left: 0%; right: 0%; top: 0%; bottom: 0%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35.42%, rgba(0, 0, 0, 0.8) 100%); 
          }
          img {width:100%; transition: .3s all ease-out;transform: scale(1);}
        }
        .tit {color:#fff; font-size:vw(24); font-weight:900; position:absolute; bottom:vw(42); left:vw(35);}
      }
    }
  }
  #section3 {  background:#f0f2f5;
    .section_inner { min-height:900px;position:relative; height:100vh;}
    .section_wrap {padding-top:0; padding:0 40px; position:absolute; top:50%; width:100%; transform: translateY(-50%);}
    .s_title { color:#000;}
    .s_desc { margin-top:vw(34); color:#000; }

    // .sect_bg { position:absolute; top:0; left:0; width:100%; height:100%; background:url('../../images/temp/Morgan-Stanley-declares-Metaverse-the-next-big-investment-trend-of.jpg'); background-size:cover;
    //   &:after {content:''; position:absolute; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.3)}
    // }
  }
  #section4 {
    background:#fff;
    .section_inner { /*padding:0 18px;*/ position:relative; height:100vh;min-height:900px;}
    .section_wrap {padding-top:0; position:absolute; top:50%; width:100%; transform: translateY(-50%); }
    .s_title { color:#000; text-align: center; margin-bottom:58px;}
    .customer_list { display:flex; flex-wrap: wrap; justify-content: space-between; width:vw(1045); margin:0 auto; position:relative;
      li { width:vw(335); margin-bottom:vw(20);
        img {width:100%;}
      }
      &:after {content:''; position: absolute;
        width: 100%;
        height: vw(143);
        left: 0;
        bottom: vw(-30);
        
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 42.19%);}
    }
  }
  #section5 {
    background:#fff;
    
    .swiper-button-prev {
      width:32px; height:32px; position:absolute; top:auto; bottom:0; left:vw(-250); margin-left:0px; border:1px solid #C3CBCD;
      background:#fff; 
      &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; }
    }
    .swiper-button-next {
        width:32px; height:32px; position:absolute; top:auto; bottom:0; left:vw(-250); margin-left:32px; border:1px solid #C3CBCD;
        background:#fff;
        &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; transform: scaleX(-1);}
    }

  }
  #section6 {
    &.active {
      .sect_bg { transform: scale(1); }
    }
    .sect_bg { transform: scale(1.2); transition: 1.6s transform;  position:absolute; top:0; left:0; width:100%; height:100%; background:url('../../images/main/bg_contact.jpg'); background-size:cover;
      &:after {content:''; position:absolute; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.3)}
    }
    .section_inner { /*padding:0 18px;*/ position:relative; height:100vh;}
    .section_wrap {padding-top:0; position:absolute; top:50%; width:100%; transform: translateY(-50%); }
    
    .btn_wrap { margin-top:vw(40);}
    .s_title { color:#fff; text-align: center;}
  }

  .news_section { 
    .fp-tableCell:before {
      content:''; position:absolute; top:50%; height:50%; width:100%; background:#f6f6f6;
    }
    .section_inner { padding:0 40px; position:relative; height:100vh;}
    .section_wrap {padding-top:0; position:absolute; top:50%; width:100%; transform: translateY(-50%); 
      padding-left:vw(250);
    }


    .news_header { position:absolute; left:0; top:50%;  margin-top:vw(-157); z-index: 2;
      .s_title{ color:#000;}
      .btn_wrap {margin-top:vw(27);}
    }
    .news_body {
      .swiper {overflow: visible;}
    
      .swiper {  margin-top:vw(80); position:relative; //margin-right:-100%;
        &:after {content:''; position:absolute; width:100%; transform:translateX(-100%) translateY(-10%); top:0; height:120%; left:vW(-20);background:linear-gradient(180deg, #fff 44.7%, #f6f6f6 44.7%);  z-index: 1;}
        .swiper-wrapper {
          .swiper-slide { width:vw(334); height:vw(480); border-radius: vw(30); overflow: hidden;position:relative;
            background-color: #fff;
            box-shadow: 2px 4px 16px rgba(195, 203, 205, 0.2);
            transition: .2s box-shadow;
            &:hover {
              box-shadow: 6px 6px 30px rgba(195, 203, 205, 0.9);
              .img_wrap {
                .img_inner{
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }
            .link {
              display: block; position: relative; height: 100%;
              &:after {content:''; width:vw(33); height:vw(14); display:block; background: url('../../images/common/news_arrow.png'); background-repeat: no-repeat; background-size: 100%; position:absolute; bottom:vw(33); right:vw(20);}
            } 
            .img_wrap {
              .img_inner{
                position:relative; overflow: hidden; width:vw(334); height:vw(200);
                img {
                  transition: .2s transform ease-out;
                  //position: absolute; width: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%);
                  width:100%; height:100%; object-fit: cover;
                }
              }
            }
            .cont_wrap { padding:vw(22) vw(35); 
              .cont_inner { 
                .tit {font-size:vw(28); font-weight: 500; line-height: 1.5; color:#000; @include ellipsis-line(3);}
                .date {position:absolute; bottom:vw(30);left:vw(35); color: #C3CBCD; font-size:vw(18); font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }

  .business_wrap { margin-top:vw(55);
    .business_inner { display:flex; flex-wrap: wrap; width:vw(1320); justify-content: space-between;
      .bs_list { border-radius: vw(30); overflow:hidden;width:vw(538);
        .bs_item { border-top:1px solid #bdbdbd; 
          &:first-child {border-top:none;}
          button { transition: .2s background; width:100%; text-align: left;
            &.on {background:#f2f2f2; 
              span {font-weight: 700; color:#004098;}
            }
            display:flex; height:vw(130); justify-content: flex-start; align-items: center; padding:vw(20) vw(40); text-align: left;
            span {
              font-size:vw(18); font-weight: 700; color:#000;line-height: 1.4; transition: .2s color;
            }
          }
        }
      }
      .bs_slide_wrap { width:vw(762); background:#f6f6f6; border-radius: vw(30); overflow:hidden;
        .bs_slide_inner { border-radius: vw(30);
          li { text-align: center;
            .img_wrap { 
              img {width:100%;}
            }
            .title{ font-size:vw(24); color:#000; font-weight: 900;}
            .txt {font-size:vw(18); font-weight: 400; margin-top:vw(15); color:#fff;
              position:absolute; bottom:vw(20); left:0;  width:100%; text-align: center;
            }
          }
        }
      }
    }
  }

  .consulting_wrap { display:flex; flex-wrap: wrap;  width:vw(1163); margin-top:vw(90); margin:vw(100) auto 0;
    .consulting_box { position:relative;
      &.box_l {width:vw(453); margin-right:vw(20);}
      &.box_r {width:vw(690); }
      &.box_b {width:100%; margin-top:vw(18); text-align: right; }
      .consult_cont { margin-top:vw(20); 
        &:first-child {margin-top:0;}
        label.label_text, .label {
          font-size:vw(18); color:#000; font-weight: 700; display:block; padding: vw(14) vw(15);
        }
        input[type="text"] {
          background: #fff; border:0; color:#000; width:100%; font-size:vw(18);
        }
        input[type="radio"] {
          background: #fff; border:0; color:#000; font-size:vw(18);
        }
        input[type="radio"] + label {
          font-size:vw(18); color:#000; font-weight: 500;
        }
        .must {
          color:#f00; display:inline-block; padding-left:vw(4);
        }
        textarea {height:vw(559); padding: vw(11) vw(17); font-weight: 500; height:calc(100% - 3.91944vw); position:absolute;}
        .sel_wrap {
          background:#fff; padding:vw(15) vw(17) vw(16);
        }
        .trp.radio-box {padding-left:vw(30); min-height:vw(24);
          & + .trp.radio-box { margin-left:vw(40); }
          label { font-size:vw(18); }
          input[type="radio"] + i {
            top:50%; margin-top:vw(-10); top:50%;
            &:after {
              width:vw(20); height:vw(20);
            }
          }
        }
      }
      .msg { display:inline-block; font-size:vw(18); font-weight: 700; color:#000; padding-right:vw(10);}
    }
  }

  .footer {position:relative; margin-top:77px;}
  .js-text_split { overflow:hidden;}
}


















:root {
  --card-width: 397px;
  --card-height: 558px;

  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}


.carousel {
  position: relative;
  width: 100%;
  height: var(--card-height); margin-top:vw(50);
  display: flex;
  justify-content: center;
  align-items: center;

}

.cardList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: auto;

  &__btn {
    --btn-size: 35px;
    width: var(--btn-size);
    height: var(--btn-size);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    border:1px solid #fff;
    transition: .2s background;
    background:rgba(255,255,255,0);
    &:hover {
      background:rgba(255,255,255,0.4);
    }
      &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; }

    .btn_page_next { margin-left:20px;
      &:disabled {opacity: 0.5; cursor: default;}
      width:32px; height:32px; border:1px solid #C3CBCD; background:none; position:relative; display:inline-block;
      &:after {content:''; position:absolute; top:50%; left:50%; width:8px; height:16px; margin-top:-8px; margin-left:-4px; background:url('../../images/common/icon_page_arrow.png'); background-size:100%; background-repeat: no-repeat; transform: scaleX(-1);}
    }

    &.btn--left {
      left: 50%;
      margin-left:vw(-300);
    }
    &.btn--right {
      right: 50%;
      margin-right:vw(-300);
      &:after {transform: scaleX(-1);}
    }

    .icon {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .cards__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
}

.card {
  --card-translateY-offset: 0;//100vh;

  border-radius: vw(30);
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--card-translateX-offset))
    translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset))
    scale(var(--card-scale-offset));
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  transition: transform var(--card-transition-duration)
    var(--card-transition-easing);
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
    transition: opacity var(--card-transition-duration)
      var(--card-transition-easing);
    opacity: calc(1 - var(--opacity));
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.current--card {
    --current-card-rotation-offset: 0;
    --card-translateX-offset: 0;
    --card-rotation-offset: var(--current-card-rotation-offset);
    --card-scale-offset: 1;
    --opacity: 0.8;
    //cursor:pointer;
  }

  &.previous--card {
    --card-translateX-offset: calc(-1 * var(--card-width) * 0.95);
    --card-rotation-offset: 52deg;
  }

  &.next--card {
    --card-translateX-offset: calc(var(--card-width) * 0.95);
    --card-rotation-offset: -52deg;
  }

  &.previous--card,
  &.next--card {
    --card-scale-offset: 0.9;
    --opacity: 0.4;
  }
}

.infoList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: var(--card-height);
  pointer-events: none;

  .info__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  perspective: 1500px;
  transform-style: preserve-3d;
  }
}

.info {
  margin-top: calc(var(--card-height) / 1.38);
  margin-left: calc(var(--card-width) );
  transform: translateZ(2rem);
  transition: transform var(--card-transition-duration)
    var(--card-transition-easing);
    width: var(--card-width);
    padding: vw(20);

  .text {
    position: relative;
    font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
    //white-space: nowrap;
    color: #fff;
    width: fit-content;
  }

  .name,
  .location {
    text-transform: uppercase;
  }

  .location {
    font-weight: 800;
  }

  .location {
    --mg-left: 40px;
    --text-size-offset: 0.12;
    font-weight: 600;
    margin-left: var(--mg-left);
    margin-bottom: calc(var(--mg-left) / 2);
    padding-bottom: 0.8rem;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #fff;
      left: 0%;
      transform: translate(calc(-1 * var(--mg-left)), -50%);
    }
    &::before {
      top: 50%;
      width: 20px;
      height: 5px;
    }
    &::after {
      bottom: 0;
      width: 60px;
      height: 2px;
    }
  }
  .name {
    font-size:vw(24); font-weight: 900;
  }
  .description {
    font-size:vw(18); font-weight: 400; margin-top:vw(15);
    //--text-size-offset: 0.065;
    font-weight: 500;
  }

  &.current--info {
    opacity: 1;
    display: block;
  }
  &.previous--info,
  &.next--info {
    opacity: 0;
    display: none;
  }
}


@media only screen and (min-width: 800px) {
  :root {
    --card-width: 397px;
    --card-height: 558px;
  }
}


.main {
.bs_acd { 
  width:42%;padding-left:vw(30);
  .bs_acd_item { border-bottom:1px solid #c3cbcd;
      .item_head { border-top:1px solid #c3cbcd; position:relative;
          button {
              display:block; line-height: 1.4; font-weight: 700; font-size: vw(18);padding:vw(15) vw(12); width:100%; text-align: left;
          }
          &:after {content:''; width:vw(16); height:vw(8); 
              background:url('../../images/common/acord_arrow.png');   background-size:100% ;
              display:block; position:absolute; top:50%; right:vw(12); margin-top:vw(-4); transform: rotate(180deg); 
             // transition: all .2s;
          }
      }
      .item_body { padding:vw(12) vw(12) vw(38); 
          display:none; 
          span {font-size:vw(18); font-weight: 400; color:#000; line-height: 1.55; min-height:vw(157); display:inline-block;}
      }
      &.on {
          .item_head { border-top:1px solid #c3cbcd;
              button { color:#004098; }
              &:after {transform: rotate(0deg); }
          }
          .item_body {
              // display:block;
          }
      }
  }
}
}

.main .en {
  .business_wrap .business_inner .bs_slide_wrap .bs_slide_inner li .txt {font-size:vw(15);}
}

.video_wrap {
  position: relative;
  &:after {content:''; position:absolute; left:0; top:0; bottom: 0; right: 0; background: transparent; z-index: 1;}
}