
/*footer */
.footer { 
  background-color: #121212; width: 100%; margin-top:190px;

  .innerfooter {
    padding: 64px 40px ; max-width: 1440px; margin: 0 auto; position:relative;

    .snsbox_icon { display: flex; margin-bottom:40px; justify-content: right;
      li { margin-right: 15px;  
        &:last-of-type { margin-right: 0; }
        .sns-icon {  
          width: 48px; height: 48px; display:inline-block;
          
          &.sns-icon01 { background-image: url(../../images/common/icon-facebook.png); }
          &.sns-icon02 { background-image: url(../../images/common/icon-linkedin.png); }
          &.sns-icon03 { background-image: url(../../images/common/icon-youtube.png); }
        } 
      }
    } 

    .footer-util {
      position:absolute; right:40px; bottom:65px;
      .btn_wrap {
        .btn { color:#fff; border:1px solid #fff; display:inline-block;}
        .btn+ .btn {
          margin-left:12px;
        }
      }
    }
    
    .footer_infobox {
      flex-direction: column;  padding-right: 200px;
      p {
        font-size: 16px; color: #b9b9b9; line-height: 1; font-weight: 400;
        &.foot-company { 
          font-weight: 700;
        }
        &.foot-info {
          margin-top:12px;
          span {display:inline-block; margin-right:30px;}
        }
        &.foot-copyright {margin-top:72px; }
      }
    }
  }
} 

#fullpage .footer { margin-top:0;  }


@media all and (max-width:$G-wMobile)  {

  
.footer { 
  margin-top:0px;

  .innerfooter {
    padding: 30px 16px 26px; 

    .snsbox_icon { display: flex; margin-bottom:24px; margin-top:54px; justify-content: left;
      li { margin-right: 20px;  
        &:last-of-type { margin-right: 0; }
        .sns-icon {  
          width: 44px; height: 44px; display:inline-block; background-size:100%;
          
          &.sns-icon01 { background-image: url(../../images/common/icon-facebook.png); }
          &.sns-icon02 { background-image: url(../../images/common/icon-linkedin.png); }
          &.sns-icon03 { background-image: url(../../images/common/icon-youtube.png); }
        } 
      }
    } 

    .footer-util {
      position:relative; right:auto; bottom:auto;
      .btn_wrap {
        .btn { color:#fff; border:1px solid #fff; display:inline-block;}
        .btn+ .btn {
          margin-left:24px;
        }
      }
    }
    
    .footer_infobox {
      flex-direction: column;  padding-right: 0px;
      p {
        font-size: 14px; color: #b9b9b9; line-height: 1.4; font-weight: 400;
        &.foot-company { 
          font-weight: 700;
        }
        &.foot-info {
          margin-top:13px;
          span {display:block; margin-right:0px;}
        }
        &.foot-copyright {margin-top:20px; }
      }
    }
  }
} 


}